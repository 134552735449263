import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { createJwt } from '../../../../utils/helpers';
import { analysisPteListeningSpecificQuestionAnswers } from '../../../../api/apiCall';
import toast, { CheckmarkIcon } from 'react-hot-toast';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import RetryModal from '../../ReadingTests/components/RetryModal';
import ScoreCardMultipleChoice from '../../ReadingTests/components/ScoreCardMultipleChoice';
import { AudioPlayer } from '../../components/AudioPlayer';

export const SelectMissingWordItem = () => {
    const questions = useSelector
    ((state) => state.other.questions);
    const catalogue = useSelector((state) => state.other.catalogue);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const user = JSON.parse(localStorage.getItem("userData"));
    const navigate=useNavigate();
    const [Isloading, setIsLoading] = useState(false);
    const [showScorecard, setShowScorecard] = useState(false);
    const [scoreData, setScoreData] = useState(null); // Store response data
    const [showRetry, setShowRetry] = useState(false);
    const currentQuestion = questions[currentQuestionIndex];
    const [selectedAnswer, setSelectedAnswer] = useState("");
    const audioRef = useRef(null);
    const [countdown, setCountdown] = useState(9);
    const [formattedTime, setFormattedTime] = useState('00:09');
    const [showAudioPlayer, setShowAudioPlayer] = useState(false);
    const countdownStopped = useRef(false);
    //console.log(currentQuestion)

    const reStart = ()=>{
        setSelectedAnswer(""); 
        setFormattedTime('00:09')
        countdownStopped.current=false;
        setCountdown(9);
    }

    useEffect(() => {
        reStart();
    }, [currentQuestionIndex, currentQuestion]);

    const resetAudio = () => {
        countdownStopped.current = true;
        setShowAudioPlayer(false);
      };    

    useEffect(() => {
        if (countdownStopped.current) return;
        if (countdown > 0) {
            // Update the countdown every second
            const countdownTimer = setInterval(() => {
                setCountdown(prev => {
                    const newCountdown = prev - 1;
                    
                    // Update formatted time immediately
                    const minutes = Math.floor(newCountdown / 60).toString().padStart(2, '0');
                    const seconds = (newCountdown % 60).toString().padStart(2, '0');
                    setFormattedTime(`${minutes}:${seconds}`);
                    
                    return newCountdown;
                });
            }, 1000);

            return () => clearInterval(countdownTimer); // Clean up the timer on unmount
        } else if(countdown===0) {
            // After countdown, show the audio player and play the audio
            setShowAudioPlayer(true);
        }
    }, [countdown]);

    const handleAnswerChange = (answerOption) => {
        setSelectedAnswer(answerOption); // Set selected answer directly
    };

    const handleAnalyzeAnswers = async () => {
        //console.log(answers)
        // const totalBlanks = Object.keys(currentQuestion.AnswerOptions).length;
        // console.log(totalBlanks)

        if (!selectedAnswer) {
            // Show a notification if no answer is selected
            toast.error("Please select an answer.");
            return; // Exit the function early
        }

        resetAudio()
        
        const data={
            uid: user.uid,
            platform: user.platform,
            uniqueDeviceId: user.uniqueDeviceId,
            time: user.time,
            questionNumbers:currentQuestion.uniqueQuestionNumber,
            categoryId: catalogue.categoryId,
            answer:selectedAnswer
        };
        const encryptedData = createJwt(data);
        const formData = new FormData();
        formData.append("encrptData", encryptedData);
        //console.log(data)
        setIsLoading(true)
        try {
            const response = await analysisPteListeningSpecificQuestionAnswers(formData);
            if (!response.data.failure) {
                  //console.log(response.data.data)
                  setScoreData(response.data.data); // Set score data from response
                  setShowScorecard(true); 

            } else {
                toast.error(response.data.errorMessage);
            }
        } catch (error) {
            toast.error("Failed to fetch questions.");
            console.error("Error fetching questions:", error);
        }finally {
            setIsLoading(false); // Stop loading
        }

    };

    const handleNextQuestion=()=>{
        //setShowScorecard(false)
        if (currentQuestionIndex < questions.length - 1) {
            setShowScorecard(false)
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
        else{
            navigate(-1)
        }
    }

    const handleBuyMembership = () => {
        navigate("/buy-membership", { replace: true });
    };

    const handleRetry=()=>{
        //const isPaidUser = false;
        const isPaidUser = user.memberShip==="Active";
        if(!isPaidUser){
          setShowRetry(true)
        }
        else{
            reStart();
            setShowScorecard(false)
        }
        
    }
    const handleRetryModal=()=>{
        setShowRetry(false);
       navigate("/pte/listening_tests/catalogue")
    }

    const handleClose=()=>{
        setShowScorecard(false);
        navigate("/pte/listening_tests/catalogue")
    }
  return (
    <div className="mt-4 flex-col space-y-2"> {/* space-y-8*/}
        <div className=' p-4'>
            <h1 className='font-semibold text-4xl'>Listening Test</h1>
            <h1 className='text-lg'>{catalogue.categoryName}</h1>
        </div>
        <div className=' px-4 flex-col space-y-4'>
            <div className='font-semibold  leading-8 text-red-500 bg-red-100 p-2'>
                You will hear a recording. At the end,the last word of group of words will be replaced by a beep. Select the correct option to complete the sentence.
            </div>
            {/* Audio element */}
            <div className='flex items-center justify-center'>
                {countdown > 0 ? (
                    // Display countdown message
                    <div className="flex rounded-xl border-2">
                        <p className='text-gray-500 p-2'>Playing audio in </p>
                        <p className='p-2 bg-yellow-200 rounded-r-xl'>{formattedTime} sec</p>
                    </div>
                ) : (
                    <AudioPlayer
                        audioSrc={currentQuestion?.questionAudioFile}
                        onAudioEnd={() => console.log('Audio ended')}
                        showAudioPlayer={showAudioPlayer}
                    />
                )}
            </div>
            <div className='font-bold py-2 px-32 leading-10 text-xl'>Select the missing word</div>
            <div className="py-2 px-28">
                <div className='flex flex-col px-4 space-y-2'>
                    {Object.entries(currentQuestion.AnswerOptions).map(([key, answerOption], index) => (
                        <label key={key} className="flex items-center space-x-2 border-2 p-4 rounded-md shadow-sm">
                            <input
                                type="radio" // Change to radio input
                                className="hidden"
                                checked={selectedAnswer === answerOption} // Check if this answer is selected
                                onChange={() => handleAnswerChange(answerOption)} // Set selected answer
                            />
                            <span className={`flex items-center justify-center w-6 h-6 border-2 rounded-full flex-shrink-0 ${selectedAnswer === answerOption ? 'bg-blue-600 border-blue-600' : 'border-gray-400'}`}>
                                {selectedAnswer === answerOption && (
                                    <CheckmarkIcon className='bg-blue-600' />
                                )}
                            </span>
                            <span>{answerOption}</span>
                        </label>
                    ))}
                </div>
            </div>
        </div>

        <div className="flex justify-end space-x-2 mt-4 px-8 py-2">
            <Link to="/pte/listening_tests/catalogue" className="bg-white border-2 py-2 px-4 rounded-md">Cancel </Link>
            <button 
                className="bg-blue-700 text-white py-2 px-4 rounded-md"
                onClick={handleAnalyzeAnswers}
            >
                Analyse Answer
            </button>

        </div>
        {Isloading ? ( // Conditional rendering for loading state
            <div className="flex justify-center items-center h-64"> {/* Adjust height as needed */}
                <LoadingSpinner /> {/* Loading spinner */}
            </div>
        ) : (
            <ScoreCardMultipleChoice
                show={showScorecard}
                data={scoreData}
                onClose={() => handleClose()}
                onNext={()=>handleNextQuestion()}
                onRetry={()=>handleRetry()}
                questionText={currentQuestion.questionText}
            />
        )}
        <RetryModal
                isOpen={showRetry}
                onClose={() => handleRetryModal()}
                onBuyMembership={handleBuyMembership}
            />
    </div>
    )
}
