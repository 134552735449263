import React, { useEffect, useRef, useState } from 'react';

export const AudioPlayer = ({ audioSrc, onAudioEnd, showAudioPlayer }) => {
    const audioRef = useRef(null);
    const [progress, setProgress] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [audioDuration, setAudioDuration] = useState(0);
    const [loadingAudio, setLoadingAudio] = useState(true);

    const updateProgress = () => {
        if (audioRef.current) {
            const current = audioRef.current.currentTime;
            setCurrentTime(current);
            const percentage = (current / audioDuration) * 100;
            setProgress(percentage);
        }
    };

    useEffect(() => {
        let isMounted = true; // Track component's mounted state
    
        if (showAudioPlayer && audioRef.current) {
            const onLoadedMetadata = () => {
                if (isMounted && audioRef.current) {
                    setAudioDuration(audioRef.current.duration);
                    setLoadingAudio(false);
                }
            };
    
            const onAudioEnded = () => {
                if (onAudioEnd) onAudioEnd();
            };
    
            audioRef.current.addEventListener('loadedmetadata', onLoadedMetadata);
            audioRef.current.addEventListener('timeupdate', updateProgress);
            audioRef.current.addEventListener('ended', onAudioEnded);
    
            const playAudio = async () => {
                try {
                    // Ensure component is still active before attempting to play
                    if (isMounted && audioRef.current) {
                        await audioRef.current.play();
                        audioRef.current.muted = false; // Unmute after play starts
                    }
                } catch (error) {
                    if (isMounted && audioRef.current) {
                        console.log('Autoplay failed, retrying with mute:', error);
                        audioRef.current.muted = true;
                        try {
                            await audioRef.current.play();
                            audioRef.current.muted = false; // Unmute after retry
                        } catch (retryError) {
                            console.error('Retry play failed:', retryError);
                        }
                    }
                }
            };
    
            playAudio();
    
            return () => {
                isMounted = false; // Prevent further actions on unmount
                if (audioRef.current) {
                    audioRef.current.pause();
                    audioRef.current.currentTime = 0;
                    audioRef.current.removeEventListener('loadedmetadata', onLoadedMetadata);
                    audioRef.current.removeEventListener('timeupdate', updateProgress);
                    audioRef.current.removeEventListener('ended', onAudioEnded);
                }
            };
        }
    }, [showAudioPlayer]);
    
    

    useEffect(()=>{
        updateProgress();
    },[currentTime])

    return (
        <div>
            {/* Custom Audio Player UI */}
            <div className="flex items-center justify-between px-4 py-2 w-full border-2 rounded-lg">
                {/* Left Side: Current Time */}
                <div className="text-sm text-gray-700">
                    {`${Math.floor(currentTime / 60).toString().padStart(2, '0')}:${(Math.floor(currentTime) % 60).toString().padStart(2, '0')}`}
                </div>

                {/* Center: Progress Bar */}
                <div className="w-72 mx-4 h-1 bg-gray-200 rounded-full relative">
                    <div
                        className="h-full bg-cyan-300 rounded-full"
                        style={{ width: `${progress}%`}} 
                    />
                        {/* Circle following the progress */}
                    <div
                        className="w-4 h-4 bg-cyan-300 rounded-full absolute  top-1/2  -translate-x-1/2 -translate-y-1/2"
                        style={{ left: `${progress}%` }} // Position the circle based on progress
                    />
                </div>

                {/* Right Side: Total Time */}
                <div className="text-sm text-gray-700">
                    {`${Math.floor(audioDuration / 60).toString().padStart(2, '0')}:${(Math.floor(audioDuration) % 60).toString().padStart(2, '0')}`}
                </div>
            </div>

            <audio
                ref={audioRef}
                src={audioSrc} // URL for the audio file
                preload="auto"
                style={{ display: 'none' }} // Hide default UI
            />
            {loadingAudio && (
                <div className="absolute inset-0 flex items-center justify-center bg-opacity-50 bg-white">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-blue-500"></div>
                </div>
            )}
        </div>
    );
};
