import React, { useState, useRef, useEffect } from "react";

const PopupWaveform = ({ onClose , onSuccess}) => {
    const canvasRef = useRef(null);
    const audioStreamRef = useRef(null);
    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);
    const [isRecording, setIsRecording] = useState(false);

    useEffect(() => {
        setIsRecording(true); // Automatically start recording when the component mounts.
        return () => {
            setIsRecording(false); // Ensure recording stops when the component unmounts.
        };
    }, []);

    useEffect(() => {
        let animationId;

        const startVisualization = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                audioStreamRef.current = stream;

                const audioContext = new AudioContext();
                const analyser = audioContext.createAnalyser();
                analyser.fftSize = 2048;

                const source = audioContext.createMediaStreamSource(stream);
                source.connect(analyser);

                audioContextRef.current = audioContext;
                analyserRef.current = analyser;

                const canvas = canvasRef.current;
                const ctx = canvas.getContext("2d");
                const bufferLength = analyser.fftSize;
                const dataArray = new Uint8Array(bufferLength);

                const drawWaveform = () => {
                    analyser.getByteTimeDomainData(dataArray);

                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    ctx.lineWidth = 2;
                    ctx.strokeStyle = "rgba(27, 202, 153, 1)";
                    ctx.beginPath();

                    const sliceWidth = canvas.width / bufferLength;
                    let x = 0;

                    dataArray.forEach((value, i) => {
                        const y = (value / 255) * canvas.height;
                        i === 0 ? ctx.moveTo(x, y) : ctx.lineTo(x, y);
                        x += sliceWidth;
                    });

                    ctx.lineTo(canvas.width, canvas.height / 2);
                    ctx.stroke();

                    animationId = requestAnimationFrame(drawWaveform);
                };

                drawWaveform();
            } catch (err) {
                console.error("Error accessing microphone:", err);
            }
        };

        if (isRecording) {
            startVisualization();
        }
        return (()=>{
            if (audioStreamRef.current) {
                audioStreamRef.current.getTracks().forEach((track) => track.stop());
                audioStreamRef.current = null;
            }
            if (audioContextRef.current) {
                audioContextRef.current.close();
                audioContextRef.current = null;
            }
            cancelAnimationFrame(animationId);
        });
    }, [isRecording]);

    const handleSuccess = () =>{
        setIsRecording(false)
        onSuccess();
    }

    const handleClose = () =>{
        setIsRecording(false)
        onClose();
    }
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center max-w-sm w-full">
                <h2 className="text-lg font-semibold mb-4">Speak and Check if Mic is Working</h2>
                <div className='text-sm font-semibold text-blue-500'>Do not use bluetooth headphone use only wire mic or headphone or inbuild mic for better result and accuracy</div>
                <div className="w-64 h-64 border-4 border-gray-300 rounded-full flex items-center justify-center mb-4">
                    <canvas
                        ref={canvasRef}
                        className="w-full h-full"
                        width={256}
                        height={256}
                    ></canvas>
                </div>
                <div className="flex space-x-2">
                    <button onClick={handleSuccess}
                     className="px-4 py-2 bg-blue-500 text-white rounded shadow hover:bg-blue-600"
                    >
                        Start Test
                    </button>
                    <button
                        onClick={handleClose}
                        className="px-4 py-2 bg-red-500 text-white rounded shadow hover:bg-red-600"
                    >
                        Change mic
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PopupWaveform;
