import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { createJwt } from '../../../../utils/helpers';
import { analysisPteSpeakingSpecificQuestionAnswers } from '../../../../api/apiCall';
import RetryModal from '../../ReadingTests/components/RetryModal';
import { ScoreCardSpeaking } from './ScoreCardSpeaking';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { Recording } from './Recording';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { AudioPlayer } from '../../components/AudioPlayer';

export const RetellLecture = () => {
  const questions = useSelector((state) => state.other.questions);
  const catalogue = useSelector((state) => state.other.catalogue);
  const user = JSON.parse(localStorage.getItem("userData"));
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const currentQuestion = questions[currentQuestionIndex];
  const [initialCountdown, setInitialCountdown] = useState(5); // Countdown before recording 40
  const [recordingCountdown, setRecordingCountdown] = useState(40); // Countdown during recording
  const [formattedInitialTime, setFormattedInitialTime] = useState('00:05');// 40
  const [isRecording, setIsRecording] = useState(false); // State to start recording
  const transcribedTextRef = useRef(''); // Store the transcribed text
  const navigate=useNavigate();
  const [loading, setLoading] = useState(false);
  const [showScorecard, setShowScorecard] = useState(false);
  const [scoreData, setScoreData] = useState(null); // Store response data
  const [showRetry, setShowRetry] = useState(false);
  const initialTimerInterval = useRef(null);
  const recordingTimerInterval = useRef(null);
  const [showAudioPlayer, setShowAudioPlayer] = useState(false);
  const [countdownPhase, setCountdownPhase] = useState('audio');
  // Track if countdown has been manually stopped
  const countdownStopped = useRef(false);

  const [audioStopped,setAudioStopped]=useState(false);

  const handleTranscribedText = (text) => {
    transcribedTextRef.current = text;
  };

  const reStart = () =>{
    clearInterval(initialTimerInterval.current);
    clearInterval(recordingTimerInterval.current);
    setFormattedInitialTime('00:05')
    setInitialCountdown(5);
    setRecordingCountdown(40)
    countdownStopped.current=false;
    setAudioStopped(false)
    setCountdownPhase('audio');
  }

  useEffect(()=>{
    reStart();
  },[currentQuestion,currentQuestionIndex])

  useEffect(() => {
    if (countdownStopped.current) return;
    if (initialCountdown > 0 && !isRecording) {
      initialTimerInterval.current = setInterval(() => {
        setInitialCountdown((prev) => {
          const newCountdown = prev - 1;
          const minutes = Math.floor(newCountdown / 60).toString().padStart(2, '0');
          const seconds = (newCountdown % 60).toString().padStart(2, '0');
          setFormattedInitialTime(`${minutes}:${seconds}`);
          return newCountdown;
        });
      }, 1000);
      return () => clearInterval(initialTimerInterval.current);
    }
    if(audioStopped){
        setShowAudioPlayer(false);
        setIsRecording(true)
        countdownStopped.current=true;
    }
    else{
        setShowAudioPlayer(true)
    }
  }, [initialCountdown]);

  useEffect(()=>{
    if (isRecording) {
        if(recordingCountdown > 0){
            recordingTimerInterval.current = setInterval(() => {
                setRecordingCountdown((prev) => prev - 1);
            }, 1000);
            return () => clearInterval(recordingTimerInterval.current);
        }
        else if(recordingCountdown===0){
            handleAnalyzeAnswers();
        }
    }
  },[isRecording,recordingCountdown])

  const resetRecorder=()=>{
    countdownStopped.current = true; // Flag to stop countdown effect
    clearInterval(initialTimerInterval.current); // Clear initial countdown
    clearInterval(recordingTimerInterval.current); // Clear recording countdown
    setInitialCountdown(0);
    setRecordingCountdown(0);
    setIsRecording(false);
    setShowAudioPlayer(false)
  }

  const handleAnalyzeAnswers = async() => {
    if(showAudioPlayer){
        toast.error('Please listen to the audio patiently.')
        return;
    }
    resetRecorder();
    let currentAnswer=transcribedTextRef.current;
    transcribedTextRef.current = '';
    const data={
        uid: user.uid,
        platform: user.platform,
        uniqueDeviceId: user.uniqueDeviceId,
        time: user.time,
        questionNumbers:currentQuestion.uniqueQuestionNumber,
        categoryId: catalogue.categoryId,
        answer:currentAnswer,
        audioDuration:40,
    };
    const encryptedData = createJwt(data);
    const formData = new FormData();
    formData.append("encrptData", encryptedData);
    setLoading(true)
    try {
        const response = await analysisPteSpeakingSpecificQuestionAnswers(formData);
        if (!response.data.failure) {
              //console.log(response.data.data)
              setScoreData(response.data.data); // Set score data from response
              setShowScorecard(true); 

        } else {
            toast.error(response.data.errorMessage);
        }
    } catch (error) {
        toast.error("Failed to fetch questions.");
        console.error("Error fetching questions:", error);
    }finally {
        setLoading(false); // Stop loading
    }
  };

    const handleNextQuestion=()=>{
        //setShowScorecard(false)
        if (currentQuestionIndex < questions.length - 1) {
            setShowScorecard(false)
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
        else{
            navigate(-1)
        }
    }
    const handleBuyMembership = () => {
        navigate("/buy-membership", { replace: true });
    };

    const handleRetry=()=>{
        const isPaidUser = user.memberShip==="Active";
        if(!isPaidUser){
         setShowRetry(true)
        }
        else{
            reStart();
            setShowScorecard(false)
        }
        
    }
    const handleRetryModal=()=>{
        setShowRetry(false);
        navigate("/pte/Speaking_tests/catalogue")
    }

    const handleClose=()=>{
        setShowScorecard(false)
        navigate("/pte/Speaking_tests/catalogue")
    }

    const handleAudioEnd = () => {
        //console.log('Audio has finished playing!');
        setAudioStopped(true)
        if (catalogue.categoryName === "Respond to situation Lecture") {
            setInitialCountdown(20);
          } else {
            setInitialCountdown(10);
          }
        setCountdownPhase('recording');
    };

    
    const handleSkipTimer = ()=>{
        setInitialCountdown(0);
    }


  return (
    <div className="mt-4 flex-col space-y-2"> {/* space-y-8*/}
        <div className=' p-4'>
            <h1 className='font-semibold text-4xl'>Speaking Test</h1>
            <h1 className='text-lg'>{catalogue.categoryName}</h1>
        </div>
        <div className=' px-4 flex-col space-y-2'>
            <div className='font-semibold  leading-10 text-orange-500'>
                {catalogue.categoryName=== "Respond to situation Lecture" ? (
                    "Listen to and read a desription of a situation. You will have 20 seconds to think about your answer. Then you will hear a beep. You will have 40 seconds to answer the question. Please answer as completely as you can."
                ) : (
                    "You'll hear a lecture. After listening to the lecture, in 10 seconds, please speak into the microphone and retell what you have just heard from the lecture in your own words. You will have 40 seconds to give your response."
                )}            
            </div>
            <div className='flex items-center justify-center'>
                {!isRecording ? (
                    initialCountdown > 0 ? (
                        countdownPhase === 'audio' ? (// Show "Playing audio in" countdown
                        <div className="flex rounded-xl border-2">
                            <p className="text-gray-500 p-2">Playing audio in</p>
                            <p className="p-2 bg-yellow-200 rounded-r-xl">{formattedInitialTime} sec</p>
                        </div>
                        ) : (// Show "Recording starts in" countdown
                        <div className="flex rounded-xl border-2">
                            <p className="text-gray-500 p-2">Recording starts in</p>
                            <p className="p-2 bg-yellow-200 rounded-r-xl">{formattedInitialTime} sec</p>
                        </div>
                        )
                    ) : (
                        !audioStopped &&(
                            <AudioPlayer
                                audioSrc={currentQuestion?.questionAudioFile}
                                onAudioEnd={handleAudioEnd}
                                showAudioPlayer={showAudioPlayer}
                            />
                        )
                    )
                ) :(
                    <Recording
                        recordingCountdown={recordingCountdown} 
                        divisor={40}
                        onTranscribedText={handleTranscribedText}
                    />
                )}
            </div>
        </div>
        <div className="flex justify-end space-x-2 mt-4 px-8 py-2">
            <button onClick={handleClose} className="bg-white border-2 py-2 px-4 rounded-md">Cancel </button>
            {initialCountdown > 0 ? (
                // Countdown Phase: Show "Skip Timer"
                <button 
                    onClick={handleSkipTimer} 
                    className="py-2 bg-blue-500 text-white rounded-md px-4"
                >
                    Skip Timer
                </button>
            ) : (
                <button 
                    className="bg-blue-700 text-white py-2 px-4 rounded-md"
                    onClick={handleAnalyzeAnswers}
                >
                    Analyse Answer
                </button>
            )}
        </div>
        {loading ? ( // Conditional rendering for loading state
              <div className="flex justify-center items-center h-64"> {/* Adjust height as needed */}
                  <LoadingSpinner/> {/* Loading spinner */}
              </div>
          ) : (
              <ScoreCardSpeaking
                  show={showScorecard}
                  data={scoreData}
                  onClose={() => handleClose()}
                  onNext={()=>handleNextQuestion()}
                  onRetry={()=>handleRetry()}
                  questionText={currentQuestion.questionText}
              />
          )}
          <RetryModal
                  isOpen={showRetry}
                  onClose={() => handleRetryModal()}
                  onBuyMembership={handleBuyMembership}
              />
    </div>
  )
}
