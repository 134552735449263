import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Divider, Grid } from '@mantine/core';
import { PiCaretLeftBold, PiCaretRightBold } from 'react-icons/pi';
import LoadingSpinner from '../../../components/LoadingSpinner';
import ProfileIconHeader from '../../../components/ProfileIconHeader';
import QuestionGroup from './components/QuestionGroup';
import { setCatalogue } from '../../../utils/redux/otherSlice';
import { createJwt } from '../../../utils/helpers';
import { pteListeningQuestionCount } from '../../../api/apiCall';
import "../components/Catalogue.css"
import CatalogueCard from '../components/CatalogueCards/CatalogueCard';

export const ListeningTestCatalogue = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedCatalogue, setSelectedCatalogue] = useState({ categoryId: null });
    const [totalQuestionCount, setTotalQuestionCount]=useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate()
    const dispatch= useDispatch();
    const user = JSON.parse(localStorage.getItem("userData"));
    const [ListeningCatalogue, setListeningCatalogue] = useState([]);

    useEffect(() => {
        const AcademicCore = user?.academicGenral;
        let catalogue = [
            {
                categoryName: "Summarize spoken item",
                categoryId: "0",
            },
            {
                categoryName: "Choose multiple answers item",
                categoryId: "1",
            },
            {
                categoryName: "Fill in the blanks",
                categoryId: "2",
            },
            {
                categoryName: "Multiple choice choose single answer item",
                categoryId: "4",
            },
            {
                categoryName: "Select missing word item",
                categoryId: "5",
            },
            {
                categoryName: "Highlight incorrect words",
                categoryId: "6",
            },
            {
                categoryName: "Write from dictation",
                categoryId: "7",
            }
        ];
    
        if (AcademicCore === 2) {
            catalogue.splice(3, 0, {
                categoryName: "Highlight correct summary item", // For Academic examType
                categoryId: "3",
            });
        }
    
        setListeningCatalogue(catalogue);
    }, [user?.academicGenral]); // Re-run if academicGenral changes

    const handleCardClick= async (catalogue)=>{
        setSelectedCatalogue(catalogue);
        dispatch(setCatalogue(catalogue));
        const data={
            uid: user.uid,
            platform: user.platform,
            uniqueDeviceId: user.uniqueDeviceId,
            time: user.time,
            categoryId: catalogue.categoryId,
        };
        const encryptedData = createJwt(data);
        const formData = new FormData();
        formData.append("encrptData", encryptedData);
        setIsLoading(true);
        try {
            const response = await pteListeningQuestionCount(formData);
            if (response.data && !response.data.failure) {
                setTotalQuestionCount(response.data.data.totalQuestionCount);
                setSelectedCatalogue(catalogue);
                setModalOpen(true);
            } else {
                console.error(response.data.errorMessage);
            }
        } catch (err) {
            console.error("Error fetching question count:", err);
        } finally {
            setIsLoading(false); // Stop loading
        }
    }

    const closeModal = () => {
        setModalOpen(false);
    };
    
  return (
    <div className="w-full lg:max-h-screen overflow-scroll pb-5  ">
    <ProfileIconHeader />
    <main className="p-4 px-[51px] max-md:px-6">
        <div className="flex max-md:hidden items-center gap-2">
            <Link to="/home">Home</Link> <PiCaretRightBold />{" "}
            <Link to="/pte/listening">Listening</Link> <PiCaretRightBold />
            <p className="text-primary-500">Tests</p>
        </div>
        <div className="pt-5 w-fit cursor-pointer flex gap-2 items-center" onClick={() => navigate('/pte/listening')}>
            <button className="lg:hidden">
                <PiCaretLeftBold />
            </button>
            <span className="heading">
                Listening Question Types
            </span>
        </div>
        <Divider my="xl" />
        {isLoading ? ( // Conditional rendering for loading state
            <div className="flex justify-center items-center h-64"> {/* Adjust height as needed */}
                <LoadingSpinner /> {/* Loading spinner */}
            </div>
        ) : (
            <Grid>
                {ListeningCatalogue?.map((catalogue) => (
                    <Grid.Col key={catalogue.categoryId} span={{ base: 12, sm: 12, lg: 12, xl: 6 }}>
                        <CatalogueCard
                            catalogue={catalogue} 
                            onClick={() => handleCardClick(catalogue)}
                        />
                    </Grid.Col>
                ))}
            </Grid>
        )}
        <QuestionGroup
            isOpen={isModalOpen}
            onClose={closeModal}
            catalogue={selectedCatalogue}
            totalcount={totalQuestionCount} // Passing the content based on selectedCatalogue
        />
    </main>
</div>
  )
}
