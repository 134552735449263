import React, { useEffect, useRef, useState } from 'react'
import { analysisPteSpeakingQuestionAnswers, pteSpeakingQuestionPaperFetch } from '../../../api/apiCall';
import { createJwt } from '../../../utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { json, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../../components/LoadingSpinner';
import toast from 'react-hot-toast';
import { BuyMembershipModal } from '../ReadingTests/components/BuyMembershipModal';
import LoadingOverlay from '../WritingTests/LoadingOverlay';
import { resetRetry } from '../../../utils/redux/otherSlice';
import { AudioPlayer } from '../components/AudioPlayer';
import { Recording } from './components/Recording';
import { ScoreCardMain } from './ScoreCardMain';

export const SpeakingMain = () => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const [questionPaper, setQuestionPaper] = useState(null);
    const [questionPaperId, setQuestionPaperId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [overlayloading, setOverlayLoading] = useState(false);
    const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0); // Track current category
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0); // Track current question
    const [ans, setAns] = useState({}); // final ans
    const [showScorecard, setShowScorecard] = useState(false);
    const [scoreData, setScoreData] = useState(null);
    const dispatch = useDispatch();
    const isRetry = useSelector((state) => state.other.retry); 
    const navigate= useNavigate()
    const hasFetched = useRef(false);
    const [isFinalStage, setIsFinalStage] = useState(false);
    const [isBuyMembership, setIsBuyMembership] = useState(false);
    const [serverMessage, setServerMessage] = useState("");

    const [initialCountdown, setInitialCountdown] = useState(40); // Countdown before recording 40
    const [recordingCountdown, setRecordingCountdown] = useState(40); // Countdown during recording
    const [formattedInitialTime, setFormattedInitialTime] = useState('00:40');// 40
    const [isRecording, setIsRecording] = useState(false); // State to start recording
    const [transcribedText, setTranscribedText] = useState(''); //answer for each category
    const initialTimerInterval = useRef(null);
    const recordingTimerInterval = useRef(null);
    const [showAudioPlayer, setShowAudioPlayer] = useState(false);
    const [countdownPhase, setCountdownPhase] = useState('audio');
    const countdownStopped = useRef(false);
  
    const [audioStopped,setAudioStopped]=useState(false);

    const paperFetch = async () => {
        const data = {
            uid: user.uid,
            platform: "web",
            uniqueDeviceId: user.uniqueDeviceId,
            time: user.time,
        };
        const encryptedData = createJwt(data);
        const formData = new FormData();
        formData.append("encrptData", encryptedData);
        try {
            const response = await pteSpeakingQuestionPaperFetch(formData);
            if (response.data && !response.data.failure) {
                setQuestionPaperId(response.data.data.questionPaperId);
                setQuestionPaper(response.data.data.questionPaper);
            } else {
                setServerMessage(response.data.errorMessage);
                setIsBuyMembership(true)
            }
        } catch (err) {
            console.error("Error fetching question count:", err);
        } finally {
            setLoading(false);
        }
    };

    const handlePaperFetch = () => {
        if(loading){
            if (!isRetry) {
                paperFetch();
            
            } else {
                const newPaperData = JSON.parse(localStorage.getItem("newpaper"));
                if (newPaperData) {
                    const { newQuestionId, paper } = newPaperData;
                    setQuestionPaperId(newQuestionId.questionPaperId);
                    setQuestionPaper(paper);
                    dispatch(resetRetry());
                } else {
                    console.error("No paper data found in localStorage.");
                }
                 setLoading(false);
            }
            
        }
    }
   
    useEffect(() => {
        if (!hasFetched.current) {
            handlePaperFetch();
            hasFetched.current = true;  // Mark as fetched
        }
    }, []);

    const categories = questionPaper ? Object.keys(questionPaper) : [];

    const currentCategory = categories[currentCategoryIndex];
    const questions = currentCategory ? questionPaper[currentCategory] : [];
    //console.log(questionPaper)

    const reStart = () =>{
        clearInterval(initialTimerInterval.current);
        clearInterval(recordingTimerInterval.current);
        setTranscribedText('');
        setIsRecording(false);
        if (currentCategory === 'ReadAloud') {
            setFormattedInitialTime('00:40')
            setInitialCountdown(40);
            setRecordingCountdown(40)
            setCountdownPhase('recording');
        }
        else if( currentCategory==='DescribeImage'){
            setFormattedInitialTime('00:25')
            setInitialCountdown(25);
            setRecordingCountdown(40);
            setCountdownPhase('recording')
        }
        else {
            countdownStopped.current=false;
            setFormattedInitialTime('00:05')// category wise switch
            setInitialCountdown(5);
            setCountdownPhase('audio');
            setAudioStopped(false)
            if(currentCategory==='RepeatSentence'){
                setRecordingCountdown(10);
            }
            else if(currentCategory==='AnswerShortQuestion'){
                setRecordingCountdown(6);
            }
            else{
                setRecordingCountdown(40);
            }
        }
      }
    
      useEffect(()=>{
        reStart();
      },[questionPaper,currentCategory,currentCategoryIndex,currentQuestionIndex])
    
      useEffect(() => {
        if (countdownStopped.current) return;
        if (initialCountdown > 0 && !isRecording) {
          initialTimerInterval.current = setInterval(() => {
            setInitialCountdown((prev) => {
              const newCountdown = prev - 1;
              const minutes = Math.floor(newCountdown / 60).toString().padStart(2, '0');
              const seconds = (newCountdown % 60).toString().padStart(2, '0');
              setFormattedInitialTime(`${minutes}:${seconds}`);
              return newCountdown;
            });
          }, 1000);
          return () => clearInterval(initialTimerInterval.current);
        }
        if(currentCategory==='ReadAloud' ||  currentCategory==='DescribeImage'){
            setIsRecording(true);
        }
        else{
            if(audioStopped){
                console.log(currentCategory)
                setShowAudioPlayer(false);// category wise
                setIsRecording(true)
                countdownStopped.current=true;
            }
            else{
                setShowAudioPlayer(true)
            }
        }
      }, [initialCountdown]);
    
      useEffect(()=>{
        if (isRecording && recordingCountdown > 0) {
            recordingTimerInterval.current = setInterval(() => {
                setRecordingCountdown((prev) => prev - 1);
            }, 1000);
            return () => clearInterval(recordingTimerInterval.current);
        }
        else{
            if(recordingCountdown==0){
                handleNext();
            }
        }
      },[isRecording,recordingCountdown])

    
      const formAnswers = async (queryId) => {
        const data = {
            uid: user.uid,
            platform: "web",
            uniqueDeviceId: user.uniqueDeviceId,
            time: user.time,
            questionPaperId: questionPaperId,
            queryId:queryId,
            answer: ans // Using the updated answers
        };
        const encryptedData = createJwt(data);
        const formData = new FormData();
        formData.append("encrptData", encryptedData);
        try{
            const response=await analysisPteSpeakingQuestionAnswers(formData)
            if (response.data.failure) {
                toast.error(response.data.errorMessage);
                return; // Exit if there's an error
            }
            else{
                return response.data.data;
            }
        }
        catch (error) {
            toast.error("Failed to fetch questions.");
            console.error("Error fetching questions:", error);
        }

    };

    const handleAnswerSubmit = async ()=>{
        let queryId = '';
        let response;
        setOverlayLoading(true)
        do {
            // Call the formAnswers function to get the response
            response = await formAnswers(queryId);
            
            if (response) {
                queryId = response.queryId; // Update queryId with the latest value
                const callbackTime = response.callBackTime* 1000; // Get the callback time
    
                await new Promise((resolve) => setTimeout(resolve, callbackTime)); // Wait for the callback time
            }
        } while (response && Object.keys(response.Result).length === 0);
        setOverlayLoading(false)
        const dataToStore = {
            results: response, // Store the results from API
            questionPaper: questionPaper // The question paper
        };
        //console.log(dataToStore.results.overallResult)
        localStorage.setItem(questionPaperId, JSON.stringify(dataToStore));
        setScoreData(response); // Set score data from response
        setShowScorecard(true);
        //console.log(JSON.stringify(response, null, 2))
    }

    useEffect(() => {
        if (isFinalStage) {
            setIsRecording(false)
            clearInterval(initialTimerInterval.current);
            clearInterval(recordingTimerInterval.current);
            handleAnswerSubmit()
        }
    }, [isFinalStage]);
    
    const handleNext = async()=>{
        if(currentCategory!='ReadAloud' && !audioStopped){
            toast.error('Please listen to the audio patiently.')
            return;
        }
        let updatedAns = { ...ans };
        let answerDuration=40;
        if(currentCategory==='RepeatSentence'){
            answerDuration=10;
        }
        else if(currentCategory==='AnswerShortDuration'){
            answerDuration=6;
        }
        if (!updatedAns[currentCategory]) {
            updatedAns[currentCategory] = [];
        }
        updatedAns[currentCategory].push({
            answer: {
                answerAudioDuration:answerDuration,
                userAnswerSubmit: transcribedText,
            },
            uniqueQuestionNumber: questions[currentQuestionIndex].uniqueQuestionNumber
        });
        setAns(updatedAns)
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else if (currentCategoryIndex < categories.length - 1) {
            setCurrentCategoryIndex(currentCategoryIndex + 1);
            setCurrentQuestionIndex(0);
        } else {
            setIsFinalStage(true);
        }
    }

    const handleSkipTimer = ()=>{
        setInitialCountdown(0);
    }

    const renderInstruction =(category)=>{
        switch (category) {
            case 'ReadAloud':
                return "Look at the text below, In 35 seconds, you must read this text aloud as naturally and clearly as possible. You have 40 seconds to Read aloud.";
            case 'RepeatSentence':
                return "You'll hear a sentence. Please repeat the sentence exactly as you heard it. You will hear the sentence only once.";
            case 'DescribeImage':
                 return "Look at the graph below, In 25 seconds, please speak into the microphone and describe in the detail what the graph is showing. You will have 40 seconds to give your response.";
            case 'RespondToSituationLecture':
                return "Listen to and read a desription of a situation. You will have 20 seconds to think about your answer. Then you will hear a beep. You will have 40 seconds to answer the question. Please answer as completely as you can.";
            case 'AnswerShortQuestion':
                return "You'll hear a question. Please give a simple and short answer. Often just one or a few words are enough.";
            case 'RetellLecture':
                return "You'll hear a lecture. After listening to the lecture, in 10 seconds, please speak into the microphone and retell what you have just heard from the lecture in your own words. You will have 40 seconds to give your response.";
            default:
                return <div>Unknown question type</div>;
        }
    }

    const handleBuyMembership = () => {
        navigate("/buy-membership");
    };

    const handleMembershipModalClose=()=>{
        setIsBuyMembership(false);
        navigate(-1)
    }

    const formatCategory =(category) =>{
        if (typeof category === 'string') {
            return category.replace(/([a-z])([A-Z])/g, '$1 $2');
        } else {
            return ''; 
        }
    }

    const handleClose = () =>{
        setShowScorecard(false);
        navigate("/pte/speaking")
    }

    const handleAudioEnd = () => {
        //console.log('Audio has finished playing!'); // for two categories this will not be called
        setAudioStopped(true)
        if(currentCategory==='RepeatSentence' ||currentCategory==='AnswerShortQuestion'){
            setIsRecording(true)
        }
        else{
            if(currentCategory=='RespondtosituationLecture'){
                setInitialCountdown(20);
            }
            else{
                setInitialCountdown(10);
            }
            setCountdownPhase('recording');
        }
    };

    const getDivisorBasedOnCategory = () => {
        switch(currentCategory) {
            case 'RepeatSentence':
                return 10;
            case 'AnswerShortQuestion':
                return 6;
            default:
                return 40;
        }
    };
    const recordDivisor = getDivisorBasedOnCategory();

   if(loading){
    return(
        <LoadingSpinner/>
    )
   }

   if(overlayloading){
        return(
            <LoadingOverlay/>
        )
    }

  return (
    <div className='p-4'>
        <h1 className='font-semibold text-4xl'>Speaking Test</h1>
        <h2 className='text-lg'>{formatCategory(categories[currentCategoryIndex])}</h2>
        <div className="question-container">
            {questionPaper && questions.length > 0 ? (
                <div>
                    <h3>Question {currentQuestionIndex + 1} of {questions.length }</h3>
                    <div className=' px-4 flex-col space-y-4'>
                        <div className='font-semibold  leading-8 text-red-500 bg-red-100 p-2'>
                            {renderInstruction(categories[currentCategoryIndex])}
                        </div>
                        <div className='flex items-center justify-center'>
                            {!isRecording ? (
                                initialCountdown > 0 ? (
                                    countdownPhase === 'audio' ? (// Show "Playing audio in" countdown
                                    <div className="flex rounded-xl border-2">
                                        <p className="text-gray-500 p-2">Playing audio in</p>
                                        <p className="p-2 bg-yellow-200 rounded-r-xl">{formattedInitialTime} sec</p>
                                    </div>
                                    ) : (// Show "Recording starts in" countdown
                                    <div className="flex rounded-xl border-2">
                                        <p className="text-gray-500 p-2">Recording starts in</p>
                                        <p className="p-2 bg-yellow-200 rounded-r-xl">{formattedInitialTime} sec</p>
                                    </div>
                                    )
                                ) : (
                                    !audioStopped &&(
                                        <AudioPlayer
                                            audioSrc={questions[currentQuestionIndex]?.questionAudioFile}
                                            onAudioEnd={handleAudioEnd}
                                            showAudioPlayer={showAudioPlayer}
                                        />
                                    )
                                )
                            ) :(
                                <Recording 
                                    isRecording={isRecording}
                                    recordingCountdown={recordingCountdown} 
                                    divisor={recordDivisor}// based on cat
                                    onTranscribedText={setTranscribedText}
                                />
                            )}
                        </div>
                        {currentCategory==='ReadAloud' && (
                            <div>
                                {questions[currentQuestionIndex].questionText}
                            </div>
                        )}
                        {currentCategory==='DescribeImage' && (
                            <div className="flex items-center justify-center">
                                <img
                                    src={questions[currentQuestionIndex].questionsImage}
                                    alt="Question"
                                />
                            </div>
                        )}
                    </div>
                    <div className="flex justify-end mt-4 ">
                        {initialCountdown > 0 ? (
                            // Countdown Phase: Show "Skip Timer"
                            <button 
                                onClick={handleSkipTimer} 
                                className="py-2 bg-blue-500 text-white rounded-md px-4"
                            >
                                Skip Timer
                            </button>
                        ) : (
                            // Recording Phase: Show Submit Answer Button Enabled
                            <button 
                                onClick={handleNext} 
                                className="py-2 bg-blue-500 text-white rounded-md px-4"
                            >
                                Submit Answer
                            </button>
                        )}
                    </div>
                </div>
            ) : (
                <div>No questions in this category.</div>
            )}
        </div>
        <ScoreCardMain
            show={showScorecard}
            data={scoreData}
            onClose={() => handleClose()}
            canRetry={false}
        />
        {isBuyMembership && (
            <BuyMembershipModal
                message={serverMessage}
                onClose={handleMembershipModalClose}
                onBuyMembership={handleBuyMembership}
            />
        )}
    </div>
  )
}
