import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import toast, { CheckmarkIcon } from 'react-hot-toast';
import { analysisPteReadingSpecificQuestionAnswers } from '../../../../api/apiCall';
import RetryModal from './RetryModal';
import { createJwt } from '../../../../utils/helpers';
import ScoreCardMultipleChoice from './ScoreCardMultipleChoice';
import LoadingSpinner from '../../../../components/LoadingSpinner';
const MultipleChoice = () =>{
    const questions = useSelector((state) => state.other.questions);
    const catalogue = useSelector((state) => state.other.catalogue);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
   
    //const [focusedIndex, setFocusedIndex] = useState(-1); // State to track which dropdown is focused

    const currentQuestion = questions[currentQuestionIndex];

    const [answers, setAnswers] = useState([]); // Initialize with empty strings

    const user = JSON.parse(localStorage.getItem("userData"));

    const [showScorecard, setShowScorecard] = useState(false);
    const [scoreData, setScoreData] = useState(null); // Store response data
    const [showRetry, setShowRetry] = useState(false);

    const navigate=useNavigate();

    useEffect(() => {
        setAnswers([])
    }, [currentQuestionIndex,currentQuestion,showScorecard]); // Re-run this effect when the currentQuestionIndex changes

    const handleAnswerChange = (answerOption) => {
        setAnswers(prevAnswers => {
            if (prevAnswers.includes(answerOption)) {
                // Remove the answer if it is already selected
                return prevAnswers.filter(answer => answer !== answerOption);
            } else {
                // Add the answer if it is not selected
                return [...prevAnswers, answerOption];
            }
        });
    };

    const handleAnalyzeAnswers = async () => {
        //console.log(answers)
        // const totalBlanks = Object.keys(currentQuestion.AnswerOptions).length;
        // console.log(totalBlanks)

        if (answers.length === 0) {
            // Show a notification if no answer is selected
            toast.error("Please select at least one answer.");
            return; // Exit the function early
        }

        const data={
            uid: user.uid,
            platform: user.platform,
            uniqueDeviceId: user.uniqueDeviceId,
            time: user.time,
            questionNumbers:currentQuestion.uniqueQuestionNumber,
            categoryId: catalogue.categoryId,
            answer:answers
        };
        const encryptedData = createJwt(data);
        const formData = new FormData();
        formData.append("encrptData", encryptedData);
        //console.log(data)
        setIsLoading(true)
        try {
            const response = await analysisPteReadingSpecificQuestionAnswers(formData);
            if (!response.data.failure) {
                  console.log(response.data.data)
                  setScoreData(response.data.data); // Set score data from response
                  setShowScorecard(true); 

            } else {
                toast.error(response.data.errorMessage);
            }
        } catch (error) {
            toast.error("Failed to fetch questions.");
            console.error("Error fetching questions:", error);
        }finally {
            setIsLoading(false); // Stop loading
        }

    };
    const handleNextQuestion=()=>{
        setShowScorecard(false)
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
        else{
            navigate(-1)
        }
    }

    const handleBuyMembership = () => {
        navigate("/buy-membership", { replace: true });
    };

    const handleRetry=()=>{
        //const isPaidUser = false;
        const isPaidUser = user.memberShip==="Active";
        if(!isPaidUser){
          setShowRetry(true)
        }
        else{
            setShowScorecard(false)
        }
        
    }
    const handleRetryModal=()=>{
        setShowRetry(false);
       navigate("/pte/reading_tests/catalogue")
    }

    const handleClose = () =>{
        setShowScorecard(false);
        navigate("/pte/reading_tests/catalogue")
    }

    if(isLoading){
        return (
            <LoadingSpinner/>
        )
    }

    return(
        <div className="mt-4 flex-col space-y-2"> {/* space-y-8*/}
            <div className=' p-4'>
                <h1 className='font-semibold text-4xl'>Reading Test</h1>
                <h1 className='text-lg'>{catalogue.categoryName}</h1>
            </div>
            <div className=' px-4 flex-col space-y-2'>
                 <div className='font-semibold  leading-10 text-orange-500'>
                    Multiple-Choice-Read the text and answer the multiple-choice question by selecting the correct responses. More than one response is correct
                 </div>
                 <div className="font-semibold py-2 px-32 leading-10">{currentQuestion.questionText}</div>
                 <div className="py-2 px-28">
                    <div className='flex flex-col px-4 space-y-2'>
                        {Object.entries(currentQuestion.AnswerOptions).map(([key, answerOption], index) => (
                            <label key={key} className="flex items-center space-x-2 border-2 p-4 rounded-md shadow-sm ">
                                <input
                                    type="checkbox"
                                    className="hidden"
                                    checked={answers.includes(answerOption)}
                                    onChange={(e) => handleAnswerChange(answerOption)}
                                />
                                 <span className={`flex items-center justify-center w-6 h-6 border-2 rounded-full flex-shrink-0 ${answers.includes(answerOption) ? 'bg-blue-600 border-blue-600' : 'border-gray-400'}`}>
                                    {answers.includes(answerOption) && (
                                        <CheckmarkIcon className='bg-blue-600'/>
                                    )}
                                </span>
                                <span>{answerOption}</span>
                            </label>
                        ))}
                    </div>
                </div>
            </div>
            <div className="flex justify-end space-x-2 mt-4 px-8 py-2">
                <Link to="/pte/reading_tests/catalogue" className="bg-white border-2 py-2 px-4 rounded-md">Cancel </Link>
                <button 
                    className="bg-blue-700 text-white py-2 px-4 rounded-md"
                    onClick={handleAnalyzeAnswers}
                >
                    Analyse Answer
                </button>

            </div>
            <ScoreCardMultipleChoice
                show={showScorecard}
                data={scoreData}
                onClose={() => handleClose()}
                onNext={()=>handleNextQuestion()}
                onRetry={()=>handleRetry()}
                questionText={currentQuestion.questionText}
            />
            <RetryModal
                isOpen={showRetry}
                onClose={() => handleRetryModal()}
                onBuyMembership={handleBuyMembership}
            />
        </div>
    );
}
export default MultipleChoice