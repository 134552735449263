import React, { useEffect, useRef, useState } from 'react'
import { analysisPteListeningQuestionAnswers, pteListeningQuestionPaperFetch } from '../../../api/apiCall';
import { createJwt } from '../../../utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../../components/LoadingSpinner';
import toast, { CheckmarkIcon } from 'react-hot-toast';
import { BuyMembershipModal } from '../ReadingTests/components/BuyMembershipModal';
import LoadingOverlay from '../WritingTests/LoadingOverlay';
import { resetRetry } from '../../../utils/redux/otherSlice';
import ScoreCardMain from './ScoreCardMain';
import { AudioPlayer } from '../components/AudioPlayer';

export const ListeningMain = () => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const [questionPaper, setQuestionPaper] = useState(null);
    const [questionPaperId, setQuestionPaperId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [overlayloading, setOverlayLoading] = useState(false);
    const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0); // Track current category
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0); // Track current question
    const [answer1, setAnswer1] = useState(''); // Summary category
    const [chooseMultipleAnswerItemAnswer, setChooseMultipleAnswerItemAnswer] = useState([]);
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const [fillInTheBlanksAnswers, setFillInTheBlanksAnswers] = useState([]);
    const [multipleChoiceSingleAnswer , setMultipleChoiceSingleAnswer]= useState("");
    const [heighlightIncorrectWordAnswers,setHeighlightIncorrectWordAnswers]=useState([]);
    const [writeFromDictationAnswer, setWriteFromDictationAnswer] = useState('');
    const [ans, setAns] = useState({}); // final ans
    const [showScorecard, setShowScorecard] = useState(false);
    const [scoreData, setScoreData] = useState(null);
    const dispatch = useDispatch();
    const isRetry = useSelector((state) => state.other.retry); 
    const navigate= useNavigate()
    const hasFetched = useRef(false);
    const [isFinalStage, setIsFinalStage] = useState(false);
    const [isBuyMembership, setIsBuyMembership] = useState(false);
    const [serverMessage, setServerMessage] = useState("");
    const [wordCount, setWordCount] = useState(0);

    const audioRef = useRef(null);
    const [countdown, setCountdown] = useState(9); // Start countdown from 10 seconds
    const [formattedTime, setFormattedTime] = useState('00:09');
    const [showAudioPlayer, setShowAudioPlayer] = useState(false); // Initially hide the audio player
    const countdownStopped = useRef(false);

    const paperFetch = async () => {
        const data = {
            uid: user.uid,
            platform: user.platform,
            uniqueDeviceId: user.uniqueDeviceId,
            time: user.time,
        };
        const encryptedData = createJwt(data);
        const formData = new FormData();
        formData.append("encrptData", encryptedData);
        
        try {
            const response = await pteListeningQuestionPaperFetch(formData);
            //console.log(response.data.data.questionPaper.WritingSummarize)
            if (response.data && !response.data.failure) {
                setQuestionPaperId(response.data.data.questionPaperId);
                setQuestionPaper(response.data.data.questionPaper);
            } else {
                setServerMessage(response.data.errorMessage);
                setIsBuyMembership(true)
            }
        } catch (err) {
            console.error("Error fetching question count:", err);
        } finally {
            setLoading(false);
        }
    };

    const handlePaperFetch = () => {
        if(loading){
            if (!isRetry) {
                paperFetch();
            
            } else {
                const newPaperData = JSON.parse(localStorage.getItem("newpaper"));
                if (newPaperData) {
                    const { newQuestionId, paper } = newPaperData;
                    setQuestionPaperId(newQuestionId.questionPaperId);
                    setQuestionPaper(paper);
                    dispatch(resetRetry());
                } else {
                    console.error("No paper data found in localStorage.");
                }
                 setLoading(false);
            }
            
        }
    }
   
    useEffect(() => {
        if (!hasFetched.current) {
            handlePaperFetch();
            hasFetched.current = true;  // Mark as fetched
        }
    }, []);

    const categories = questionPaper ? Object.keys(questionPaper) : [];

    const currentCategory = categories[currentCategoryIndex];
    const questions = currentCategory ? questionPaper[currentCategory] : [];

    const reStart = ()=>{
        if(currentCategory==='summarizeSpokenItem'){
            setAnswer1('');
            setWordCount(0)
        }
        else if(currentCategory==='ChooseMultipleAnswerItem'){
            setChooseMultipleAnswerItemAnswer([]);
        }
        else if(currentCategory==='FillInTheBlanks'){
            const totalBlanks = Object.keys(questions[currentQuestionIndex].correctAnswer).length;
            setFillInTheBlanksAnswers(Array(totalBlanks).fill(""));
        }
        else if((currentCategory==='HighLightCorrectSummary') || (currentCategory==='MultipleChoiceSingleAnswer') || (currentCategory==='SelectMissingWord')){
            setMultipleChoiceSingleAnswer("");
        }
        else if(currentCategory==='HeighlightIncorrectWord'){
            setHeighlightIncorrectWordAnswers([]);
        }
        else if(currentCategory==='WriteFromDictation'){
            setWriteFromDictationAnswer('');
        }
        setFormattedTime('00:09')
        countdownStopped.current=false;
        setCountdown(9);
    }

    useEffect(() => {
        if (questionPaper) {

            if (questions && questions.length > 0) {
                reStart();
            }
            
        }
    }, [questionPaper,currentQuestionIndex,currentCategoryIndex]);

    const resetAudio = () => {
        countdownStopped.current = true;
        setShowAudioPlayer(false);
      };    

    useEffect(() => {
        if (countdownStopped.current) return;
        if (countdown > 0) {
            // Update the countdown every second
            const countdownTimer = setInterval(() => {
                setCountdown(prev => {
                    const newCountdown = prev - 1;
                    
                    // Update formatted time immediately
                    const minutes = Math.floor(newCountdown / 60).toString().padStart(2, '0');
                    const seconds = (newCountdown % 60).toString().padStart(2, '0');
                    setFormattedTime(`${minutes}:${seconds}`);
                    
                    return newCountdown;
                });
            }, 1000);

            return () => clearInterval(countdownTimer); // Clean up the timer on unmount
        } else if(countdown===0) {
            // After countdown, show the audio player and play the audio
            setShowAudioPlayer(true);
        }
    }, [countdown]);

    const formAnswers = async (queryId) => {
        const data = {
            uid: user.uid,
            platform: user.platform,
            uniqueDeviceId: user.uniqueDeviceId,
            time: user.time,
            questionPaperId: questionPaperId,
            queryId:queryId,
            answer: ans // Using the updated answers
        };
        const encryptedData = createJwt(data);
        const formData = new FormData();
        formData.append("encrptData", encryptedData);
        try{
            const response=await analysisPteListeningQuestionAnswers(formData)
            if (response.data.failure) {
                toast.error(response.data.errorMessage);
                return; // Exit if there's an error
            }
            else{
                return response.data.data;
            }
        }
        catch (error) {
            toast.error("Failed to fetch questions.");
            console.error("Error fetching questions:", error);
        }

    };

    const handleAnswerSubmit = async ()=>{
        let queryId = '';
        let response;
        setOverlayLoading(true)
        do {
            // Call the formAnswers function to get the response
            response = await formAnswers(queryId);
            
            if (response) {
                queryId = response.queryId; // Update queryId with the latest value
                const callbackTime = response.callBackTime* 1000; // Get the callback time
    
                await new Promise((resolve) => setTimeout(resolve, callbackTime)); // Wait for the callback time
            }
        } while (response && Object.keys(response.Result).length === 0);
        setOverlayLoading(false)
        const dataToStore = {
            results: response, // Store the results from API
            questionPaper: questionPaper // The question paper
        };
        //console.log(dataToStore.results.overallResult)
        localStorage.setItem(questionPaperId, JSON.stringify(dataToStore));
        setScoreData(response); // Set score data from response
        setShowScorecard(true);
        //console.log(response.Result)
    }

    useEffect(() => {
        if (isFinalStage) {
            handleAnswerSubmit()
        }
    }, [isFinalStage]);

    const handleBuyMembership = () => {
        // Logic to handle navigation to the membership purchase page
        navigate("/buy-membership");
    };

    const handleMembershipModalClose=()=>{
        setIsBuyMembership(false);
        navigate(-1)
    }

    const countWords = (text) => {
        return text.trim().split(/\s+/).filter(word => word.length > 0).length;
    };

    const handleTextChange = (e) => {
        const value = e.target.value;
        const words = countWords(value);
        setWordCount(words);
        setAnswer1(value);
    }

    const handleChooseMultipleAnswerItemAnswerChange = (answerOption) => {
        setChooseMultipleAnswerItemAnswer(prevAnswers => {
            if (prevAnswers.includes(answerOption)) {
                // Remove the answer if it is already selected
                return prevAnswers.filter(answer => answer !== answerOption);
            } else {
                // Add the answer if it is not selected
                return [...prevAnswers, answerOption];
            }
        });
    }

    const handleFillInTheBlanksAnswersChange = (index, value) => {
        setFillInTheBlanksAnswers(prevAnswers => {
            const updatedAnswers = [...prevAnswers]; // Spread to create a copy
            updatedAnswers[index] = value; // Update the specific index
            return updatedAnswers;
        });
    };

    const handleMultipleChoiceSingleAnswerChange = (answerOption) => {
        setMultipleChoiceSingleAnswer(answerOption); // Set selected answer directly
    };

    const handleWriteFromDictationAnswerChange =(e)=>{
        const value = e.target.value;
        setWriteFromDictationAnswer(value);
    }

    const RendersummarizeSpokenItem=(question)=>{
        return(
            <>
              <div className="font-bold py-2 px-32 leading-10 text-xl">Write your report within 50-70 words</div>
              <div className="relative px-32">
                  <textarea 
                      className="w-full p-2 bg-gray-100  rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      value={answer1}
                      placeholder="Start writing your answer here..." 
                      rows="15"
                     onChange={handleTextChange} // You need this function to handle changes
                  />
  
                  {/* Character Counter */}
                  <div className="text-right text-gray-500 pt-2">
                      {wordCount}/70
                  </div>
              </div>
          </>
        )
    }

    const ChooseMultipleAnswerItem = (question)=>{
        return(
            <>
                <div className="font-semibold py-2 px-32 leading-10 text-xl">{question.questionText}</div>
                <div className="py-2 px-28">
                    <div className='flex flex-col px-4 space-y-2'>
                        {Object.entries(question.AnswerOptions).map(([key, answerOption], index) => (
                            <label key={key} className="flex items-center space-x-2 border-2 p-4 rounded-md shadow-sm ">
                                <input
                                    type="checkbox"
                                    className="hidden"
                                    checked={chooseMultipleAnswerItemAnswer.includes(answerOption)}
                                    onChange={(e) => handleChooseMultipleAnswerItemAnswerChange(answerOption)}
                                />
                                    <span className={`flex items-center justify-center w-6 h-6 border-2 rounded-full flex-shrink-0 ${chooseMultipleAnswerItemAnswer.includes(answerOption) ? 'bg-blue-600 border-blue-600' : 'border-gray-400'}`}>
                                    {chooseMultipleAnswerItemAnswer.includes(answerOption) && (
                                        <CheckmarkIcon className='bg-blue-600'/>
                                    )}
                                </span>
                                <span>{answerOption}</span>
                            </label>
                        ))}
                    </div>
                </div>
            </>
        )
    }

    const RenderFillInTheBlanks = (question)=>{
        const parts = question.questionText.split(/(\*+)/); // Split the text by asterisks
        let blankCounter=0;
        return parts.map((part, index) => {
            if (/^\*+$/.test(part)) {
                const currentcounter=blankCounter++;
                return (
                    <input
                        key={`blank-${currentcounter}`} // Use blankCounter as the key
                        className={`mb-2 py-1 px-2 border-2 rounded-md ${focusedIndex === currentcounter ? 'border-blue-500' : 'border-gray-400'}`}
                        onChange={(e) => handleFillInTheBlanksAnswersChange(currentcounter, e.target.value)}
                        value={fillInTheBlanksAnswers[currentcounter] || ""}
                        onFocus={() => {setFocusedIndex(currentcounter)}}z
                        onBlur={() => setFocusedIndex(-1)}
                        placeholder="Write your answer"
                    />
                );
            }

            return <span key={index}>{part}</span>; // Render the text part
        });
    }

    const RenderMultipleChoiceSingleAnswer = (question)=>{
        return(
            <>
                <div className='font-bold py-2 px-32 leading-10 text-xl'>
                {categories[currentCategoryIndex] === 'SelectMissingWord' 
                    ? "Select the missing word" :"Choose the option that is most relevant to the recording"}</div>
                <div className="py-2 px-28">
                    <div className='flex flex-col px-4 space-y-2'>
                        {Object.entries(question.AnswerOptions).map(([key, answerOption], index) => (
                            <label key={key} className="flex items-center space-x-2 border-2 p-4 rounded-md shadow-sm">
                                <input
                                    type="radio" // Change to radio input
                                    className="hidden"
                                    checked={multipleChoiceSingleAnswer === answerOption} // Check if this answer is selected
                                    onChange={() => handleMultipleChoiceSingleAnswerChange(answerOption)} // Set selected answer
                                />
                                <span className={`flex items-center justify-center w-6 h-6 border-2 rounded-full flex-shrink-0 ${multipleChoiceSingleAnswer === answerOption ? 'bg-blue-600 border-blue-600' : 'border-gray-400'}`}>
                                    {multipleChoiceSingleAnswer === answerOption && (
                                        <CheckmarkIcon className='bg-blue-600' />
                                    )}
                                </span>
                                <span>{answerOption}</span>
                            </label>
                        ))}
                    </div>
                </div>
            </>
        )
    }

    const handleWordClick = (word, startIndex, endIndex) => {
        const existingAnswerIndex = heighlightIncorrectWordAnswers.findIndex(
            ans => ans.word === word && ans.startIndex === startIndex && ans.endIndex === endIndex
        );
    
        if (existingAnswerIndex === -1) {
            setHeighlightIncorrectWordAnswers([
                ...heighlightIncorrectWordAnswers,
                { word, startIndex, endIndex }
            ]);
        } else {
            setHeighlightIncorrectWordAnswers(heighlightIncorrectWordAnswers.filter((_, idx) => idx !== existingAnswerIndex));
        }
    };

    const parseWordsWithIndices = (text) => {
        const wordsWithIndices = [];
        let word = "";
        let startIndex = 0;
    
        for (let i = 0; i < text.length; i++) {
            const char = text[i];
    
            if (char === " " || i === text.length - 1) {
                if (char !== " ") {
                    word += char; // Add last character if it's not a space
                }
    
                if (word) {
                    const endIndex = startIndex + word.length - 1;
                    wordsWithIndices.push({ word, startIndex, endIndex });
                }
    
                // Reset word and set startIndex for the next word
                word = "";
                startIndex = i + 1;
            } else {
                if (word === "") startIndex = i; // Set start index for the new word
                word += char;
            }
        }
    
        return wordsWithIndices;
    };    
   
    const renderTextWithHighlight = (question) => {
        const wordsWithIndices = parseWordsWithIndices(question.questionText);
        return(wordsWithIndices.map(({ word, startIndex, endIndex }) => {
            const uniqueKey = `${word}-${startIndex}-${endIndex}`;
    
            const isHighlighted = heighlightIncorrectWordAnswers.some(ans =>
                ans.word === word && ans.startIndex === startIndex && ans.endIndex === endIndex
            );
    
            return (
                <span
                    key={uniqueKey}
                    onClick={() => handleWordClick(word, startIndex, endIndex)}
                    className={`mx-1 cursor-pointer ${isHighlighted ? 'bg-yellow-300' : ''}`}
                >
                    {word}
                </span>
            );
        })
      )
    };

    const RenderHeighlightIncorrectWord = (question)=>{
        return(
            <>
                <div className='font-bold py-2 px-32 leading-10 text-xl'>Tap on words to highlight unmatch words</div>
                <div className="font-semibold py-2 px-32 leading-10 flex flex-wrap">{renderTextWithHighlight(question)}</div>
            </>
        )
    }

    const RenderWriteFromDictation = () =>{
       return(
            <>
                <div className="font-bold py-2 px-32 leading-10 text-xl">Write sentence which you just heard.</div>
                <div className="relative px-32">
                    <textarea 
                        className="w-full p-2 bg-gray-100  rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={writeFromDictationAnswer}
                        placeholder="Start writing your answer here..." 
                        rows="15"
                       onChange={handleWriteFromDictationAnswerChange} // You need this function to handle changes
                    />
                </div>
            </>
       )
    }

    const renderQuestion = (category, question) => {
        switch (category) {
            case 'summarizeSpokenItem':
                return RendersummarizeSpokenItem(question);
            case 'ChooseMultipleAnswerItem':
                return ChooseMultipleAnswerItem(question);
            case 'FillInTheBlanks':
                 return RenderFillInTheBlanks(question);
            case 'HighLightCorrectSummary':
                return RenderMultipleChoiceSingleAnswer(question);
            case 'MultipleChoiceSingleAnswer':
                return RenderMultipleChoiceSingleAnswer(question);
            case 'SelectMissingWord':
                return RenderMultipleChoiceSingleAnswer(question);
            case 'HeighlightIncorrectWord':
                return RenderHeighlightIncorrectWord(question);
            case 'WriteFromDictation':
                return RenderWriteFromDictation();
            default:
                return <div>Unknown question type</div>;
        }
    };

    const renderInstruction =(category)=>{
        switch (category) {
            case 'summarizeSpokenItem':
                return "You will hear a short report and need to write a summary for a student who missed it. Your summary should be between 50-70 words. It will be graded on how well you capture the main points and the clarity of your writing.";
            case 'ChooseMultipleAnswerItem':
                return "Listen to the recording and answer the question by choosing all the correct options. You will need to pick more than one answer.";
            case 'FillInTheBlanks':
                 return "Listen the below recording carefully to fill in the blanks";
            case 'HighLightCorrectSummary':
                return "Listen to the recording, then select the paragraph that best matches what was said in the recording.";
            case 'MultipleChoiceSingleAnswer':
                return "Listen to the recording, then choose the correct answer. Only one option is correct.";
            case 'SelectMissingWord':
                return "You will hear a recording. At the end,the last word of group of words will be replaced by a beep. Select the correct option to complete the sentence.";
            case 'HeighlightIncorrectWord':
                return "You will hear a recording. Below is a transcript of the recording,but some words are different from what the speaker said. Please tap on the words that dont match.";
            case 'WriteFromDictation':
                return "You will hear a sentence. Type sentence in the box below exactly as you hear it. Write as much as the sentences as you can. You will hear the sentence only once.";
            default:
                return <div>Unknown question type</div>;
        }
    }

    const handleNext = async() => {
        let updatedAns = { ...ans }; // Create a copy of the current ans object

        if (currentCategory === 'summarizeSpokenItem') {
            if (wordCount<70) {
                toast.error("Minimum Word Count is 70");
                return; 
            }
            resetAudio()
          if (!updatedAns[currentCategory]) {
            updatedAns[currentCategory] = [];
          }
          updatedAns[currentCategory].push({
            answer: answer1,
            uniqueQuestionNumber: questions[currentQuestionIndex].uniqueQuestionNumber
          });
         } else if (currentCategory === 'ChooseMultipleAnswerItem') {
           if (chooseMultipleAnswerItemAnswer.length===0) {
             toast.error("Please select at least one answer.");
             return;
           }
           resetAudio()
          if (!updatedAns[currentCategory]) {
            updatedAns[currentCategory] = [];
          }
          updatedAns[currentCategory].push({
            answer: chooseMultipleAnswerItemAnswer,
            uniqueQuestionNumber: questions[currentQuestionIndex].uniqueQuestionNumber
          });
         }else if (currentCategory === 'FillInTheBlanks') {
          const allAnswersSelected = fillInTheBlanksAnswers.every((answer) => answer !== '');
          if (!allAnswersSelected) {
            toast.error('Please select all answers.');
            return;
          }
          resetAudio()
          const formattedAnswers = {};
          fillInTheBlanksAnswers.forEach((answer, index) => {
            formattedAnswers[String(index + 1)] = answer;
          });
    
          if (!updatedAns[currentCategory]) {
            updatedAns[currentCategory] = [];
          }
          updatedAns[currentCategory].push({
            answer: formattedAnswers,
            uniqueQuestionNumber: questions[currentQuestionIndex].uniqueQuestionNumber
          });
        } else if ((currentCategory === 'HighLightCorrectSummary') || (currentCategory==='MultipleChoiceSingleAnswer') || (currentCategory==='SelectMissingWord')){
            if (multipleChoiceSingleAnswer.trim() === "") {
                toast.error("Please select an answer.");
                return;
            }
            resetAudio()
            if (!updatedAns[currentCategory]) {
                updatedAns[currentCategory] = [];
            }
            updatedAns[currentCategory].push({
                answer: multipleChoiceSingleAnswer,
                uniqueQuestionNumber: questions[currentQuestionIndex].uniqueQuestionNumber
            });
        }else if(currentCategory==='HeighlightIncorrectWord'){
         if (heighlightIncorrectWordAnswers.length===0) {
            toast.error("Please select at least one answer.");
            return;
          }
          resetAudio()
         if (!updatedAns[currentCategory]) {
           updatedAns[currentCategory] = [];
         }
         updatedAns[currentCategory].push({
           answer: heighlightIncorrectWordAnswers,
           uniqueQuestionNumber: questions[currentQuestionIndex].uniqueQuestionNumber
         });
        } else if(currentCategory==='WriteFromDictation'){
            if (writeFromDictationAnswer.trim() === "") {
                toast.error("Please select an answer.");
                return;
            }
            resetAudio();
            if (!updatedAns[currentCategory]) {
                updatedAns[currentCategory] = [];
            }
            updatedAns[currentCategory].push({
                answer: writeFromDictationAnswer,
                uniqueQuestionNumber: questions[currentQuestionIndex].uniqueQuestionNumber
            });
        }
        setAns(updatedAns)
        if (currentQuestionIndex < questions.length - 1) {
            // Move to next question in the same category
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else if (currentCategoryIndex < categories.length - 1) {
            // Move to first question of the next category
            setCurrentCategoryIndex(currentCategoryIndex + 1);
            setCurrentQuestionIndex(0);
        } else {
            setIsFinalStage(true);
        }
    };

    const formatCategory =(category) =>{
        if (typeof category === 'string') {
            return category.replace(/([a-z])([A-Z])/g, '$1 $2');
        } else {
            return ''; 
        }
    }
    
    const handleClose = () =>{
        setShowScorecard(false);
        navigate("/pte/listening")
    }

   if(loading){
    return(
        <LoadingSpinner/>
    )
   }

   if(overlayloading){
        return(
            <LoadingOverlay/>
        )
    }


  return (
    <div className='p-4'>
        <h1 className='font-semibold text-4xl'>Listening Test</h1>
        <h2 className='text-lg'>{formatCategory(categories[currentCategoryIndex])}</h2>
        <div className="question-container">
            {questionPaper && questions.length > 0 ? (
                <div>
                    <h3>Question {currentQuestionIndex + 1} of {questions.length }</h3>
                    {/* Render the current question based on its type */}
                    <div className=' px-4 flex-col space-y-4'>
                        <div className='font-semibold  leading-8 text-red-500 bg-red-100 p-2'>
                            {renderInstruction(categories[currentCategoryIndex])}
                        </div>
                        {/* Audio element */}
                        <div className='flex items-center justify-center'>
                            {countdown > 0 ? (
                                // Display countdown message
                                <div className="flex rounded-xl border-2">
                                    <p className='text-gray-500 p-2'>Playing audio in </p>
                                    <p className='p-2 bg-yellow-200 rounded-r-xl'>{formattedTime} sec</p>
                                </div>
                            ) : (
                                <AudioPlayer
                                    audioSrc={questions[currentQuestionIndex]?.questionAudioFile}
                                    onAudioEnd={() => console.log('Audio ended')}
                                    showAudioPlayer={showAudioPlayer}
                                />
                            )}
                        </div>
                        {renderQuestion(categories[currentCategoryIndex],questions[currentQuestionIndex])}
                    </div>
                    <div className="flex justify-end mt-4 ">
                        <button onClick={handleNext} className="py-2 bg-blue-500 text-white rounded-md px-4">
                            Submit Answer
                        </button>
                    </div>
                </div>
            ) : (
                <div>No questions in this category.</div>
            )}
        </div>
        <ScoreCardMain
            show={showScorecard}
            data={scoreData}
            onClose={() => handleClose()}
            canRetry={false}
        />
        {isBuyMembership && (
            <BuyMembershipModal
                message={serverMessage}
                onClose={handleMembershipModalClose}
                onBuyMembership={handleBuyMembership}
            />
        )}
    </div>
  )
}
