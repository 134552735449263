import React, { useEffect, useState } from 'react';
import star from '../../../../assets/images/star.png';
import Check from '../../../../assets/images/Check.png';
import X from '../../../../assets/images/X.png';
import { ReactComponent as CloseGray } from '../../../../assets/svg/close_grey.svg';
import { useSelector } from 'react-redux';
const ScoreCardMultipleChoice = ({ show, data, onClose ,onNext, onRetry, questionText}) => {
    const [showAnswers, setShowAnswers] = useState(false);
    useEffect(() => {
        if (!show) {
            setShowAnswers(false);
        }
    }, [show]);
  
    const catalogue = useSelector((state) => state.other.catalogue);

    if (!show) return null;

    const { checkedAnswers, correctAnswers, overallResult } = data;
    const handleShowAnswers = () => {
        if (!showAnswers) {
            setShowAnswers(true);
        }
    };
   
    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white rounded-lg p-6 shadow-lg w-11/12 md:w-2/3 max-h-[90%] overflow-auto relative border-2">
                <div className='flex justify-between '>
                    <h2 className="text-xl font-bold mb-2">Result</h2>
                    <CloseGray 
                        onClick={onClose} 
                        aria-label="Close" 
                        className="w-4 h-4 cursor-pointer m-1" 
                    />
                </div>
                <h3 className="mb-2 text-gray-500">Analyse your answers with the correct answers</h3>
                <div className="flex justify-center items-center mb-4">
                    <div className="w-32 h-32 bg-yellow-100 rounded-full flex items-center justify-center space-x-2 border border-orange-200">
                        {/* Circular background */}
                        <div className="text-3xl font-bold">
                            {overallResult}
                        </div>
                        {/* Star Icon */}
                        <div>
                            <img 
                                src={star}
                                alt="star-image"
                                className='w-10 h-10'
                            />
                        </div>
                    </div>
                </div>
                <div className=' flex-col'>
                    {catalogue.categoryName!=='Re-order Paragraph' && catalogue.categoryName !== 'Choose multiple answers item' && (
                        <div className=' p-2'>
                            {questionText}
                        </div>
                    )}
                    <div className='flex flex-col space-y-4  justify-center'>
                        <div className=' space-y-2 p-2'>
                            <p className="font-bold  ">Your Answers</p>
                            <div className='flex-col space-y-4 px-4 '>
                                {Object.entries(checkedAnswers).map(([key, answer]) => {
                                    if (!answer || !answer.Answer) return null;

                                    // Determine styles based on correctness
                                    const isCorrect = answer.Correct === '0'; // Assuming '0' means correct
                                    const TickMark = () => (
                                        <div className="w-5 h-5">
                                            <img src={Check} alt="Tick" className="w-full h-full" />
                                        </div>
                                    );

                                    const CrossMark = () => (
                                        <div className="w-5 h-5">
                                            <img src={X} alt="Tick" className="w-full h-full" />
                                        </div>
                                    );

                                    // Background color
                                    const bgColor = isCorrect ? 'bg-green-100' : 'bg-red-100';

                                    return (
                                        <div className={`flex items-start justify-between space-x-2 px-4 py-2 rounded-md ${bgColor}`} key={key}>
                                            <span className="flex-grow">{answer.Answer}</span>
                                            <span className="flex-shrink-0">
                                                {isCorrect ? <TickMark /> : <CrossMark />}
                                            </span>
                                        </div>
                                    );

                                })}
                            </div>
                        </div>
                        <button 
                            onClick={handleShowAnswers}
                            className="w-full py-2 border-2 border-gray-500  rounded-full hover:bg-blue-600 hover:text-white focus:outline-none"
                        >
                            Show all correct answers
                        </button>
                         {showAnswers && <div className='space-y-2 p-2'>
                            <p className="font-bold">
                                {catalogue.categoryName === 'Re-order Paragraph' ? 'Correct Answer Order' : 'Correct Answers'}
                            </p>
                            <div className='flex-col space-y-4 px-4 '>
                                {correctAnswers.map((answer, index) => (
                                    <div className='px-4 py-2 bg-gray-100 rounded-md' key={index}>
                                        <span>{answer}</span>
                                    </div>
                                ))}
                            </div>
                        </div>}
                    </div>
                </div>
                <div className="flex justify-end space-x-2 mt-4">
                        <button
                            className="border-2 text-black py-2 px-8 rounded-md"
                            onClick={onNext}
                        >
                            Next
                        </button>
                        <button
                            className="bg-blue-600 text-white py-2 px-8 rounded-md"
                            onClick={onRetry}
                        >
                            Retry
                        </button>
                        
                </div>
            </div>
        </div>
    );
};

export default ScoreCardMultipleChoice;
