import React, { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { analysisPteListeningSpecificQuestionAnswers } from '../../../../api/apiCall';
import { createJwt } from '../../../../utils/helpers';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import RetryModal from '../../ReadingTests/components/RetryModal';
import ScoreCard from '../../ReadingTests/components/ScoreCard';
import { AudioPlayer } from '../../components/AudioPlayer';

export const FillInTheBlanksListening = () => {
  const questions = useSelector
  ((state) => state.other.questions);
  const catalogue = useSelector((state) => state.other.catalogue);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const user = JSON.parse(localStorage.getItem("userData"));
  const navigate=useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showScorecard, setShowScorecard] = useState(false);
  const [scoreData, setScoreData] = useState(null); // Store response data
  const [showRetry, setShowRetry] = useState(false);
  const currentQuestion = questions[currentQuestionIndex];
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const audioRef = useRef(null);
  const [countdown, setCountdown] = useState(9); // Start countdown from 10 seconds
  const [formattedTime, setFormattedTime] = useState('00:09');
  const [showAudioPlayer, setShowAudioPlayer] = useState(false); // Initially hide the audio player
  const countdownStopped = useRef(false);

  //console.log(currentQuestion)

    const reStart = ()=>{
        const totalBlanks = Object.keys(currentQuestion.correctAnswer).length;
        setAnswers(Array(totalBlanks).fill(""));
        setFormattedTime('00:09')
        countdownStopped.current=false;
        setCountdown(9);
    }

  useEffect(() => {
    reStart();
}, [currentQuestionIndex,currentQuestion]);

    const resetAudio = () => {
    countdownStopped.current = true;
    setShowAudioPlayer(false);
  };    

  useEffect(() => {
    if (countdownStopped.current) return;
    if (countdown > 0) {
        // Update the countdown every second
        const countdownTimer = setInterval(() => {
            setCountdown(prev => {
                const newCountdown = prev - 1;
                
                // Update formatted time immediately
                const minutes = Math.floor(newCountdown / 60).toString().padStart(2, '0');
                const seconds = (newCountdown % 60).toString().padStart(2, '0');
                setFormattedTime(`${minutes}:${seconds}`);
                
                return newCountdown;
            });
        }, 1000);

        return () => clearInterval(countdownTimer); // Clean up the timer on unmount
    } else if(countdown===0) {
        // After countdown, show the audio player and play the audio
        setShowAudioPlayer(true);
    }
}, [countdown]);

  const handleAnswerChange = (index, value) => {
    setAnswers(prevAnswers => {
        const updatedAnswers = [...prevAnswers]; // Spread to create a copy
        updatedAnswers[index] = value; // Update the specific index
        return updatedAnswers;
    });
  };
  
  const renderQuestionText = (questionText) => {
    const parts = questionText.split(/(\*+)/); // Split the text by asterisks
    let blankCounter=0;
    return parts.map((part, index) => {
        if (/^\*+$/.test(part)) {
            const currentcounter=blankCounter++;
            return (
                <input
                    key={`blank-${currentcounter}`} // Use blankCounter as the key
                    className={`mb-2 py-1 px-2 border-2 rounded-md ${focusedIndex === currentcounter ? 'border-blue-500' : 'border-gray-400'}`}
                    onChange={(e) => handleAnswerChange(currentcounter, e.target.value)}
                    value={answers[currentcounter] || ""}
                    onFocus={() => {setFocusedIndex(currentcounter)}}z
                    onBlur={() => setFocusedIndex(-1)}
                    placeholder="Write your answer"
                />
            );
        }

        return <span key={index}>{part}</span>; // Render the text part
    });
  };

  const handleAnalyzeAnswers = async () => {
    const allAnswersSelected = answers.every(answer => answer !== "");
    
    if (!allAnswersSelected) {
        toast.error('Please write all answers.'); // Show error toast if not all answers are selected
        return;
    }

    resetAudio()

    const formattedAnswers = {};
    answers.forEach((answer, index) => {
        formattedAnswers[String(index + 1)] = answer; // Keys start from "1", "2", etc.
    });
    const data={
        uid: user.uid,
        platform: user.platform,
        uniqueDeviceId: user.uniqueDeviceId,
        time: user.time,
        questionNumbers:currentQuestion.uniqueQuestionNumber,
        categoryId: catalogue.categoryId,
        answer:formattedAnswers
    };
    const encryptedData = createJwt(data);
    const formData = new FormData();
    formData.append("encrptData", encryptedData);
    setIsLoading(true);
    try {
        const response = await analysisPteListeningSpecificQuestionAnswers(formData);
        if (!response.data.failure) {
              console.log(response)
              setScoreData(response.data.data); // Set score data from response
              setShowScorecard(true); 

        } else {
            toast.error(response.data.errorMessage);
        }
    } catch (error) {
        toast.error("Failed to fetch questions.");
        console.error("Error fetching questions:", error);
    }finally {
        setIsLoading(false); // Stop loading
    }

  };
  const handleNextQuestion=()=>{
      if (currentQuestionIndex < questions.length - 1) {
          setShowScorecard(false)
          setCurrentQuestionIndex(currentQuestionIndex + 1);
      }
      else{
          navigate(-1)
      }
  }

  const handleBuyMembership = () => {
      navigate("/buy-membership", { replace: true });
  };

  const handleRetry=()=>{
      //const isPaidUser = false;
      const isPaidUser = user.memberShip==="Active";
      if(!isPaidUser){
        setShowRetry(true)
      }
      else{
          reStart();
          setShowScorecard(false)
      }
      
  }
  const handleRetryModal=()=>{
      setShowRetry(false);
    navigate("/pte/listening_tests/catalogue")
  }

  const handleClose = () =>{
      setShowScorecard(false);
      navigate("/pte/listening_tests/catalogue")
  }

  if(isLoading){
    return (
        <LoadingSpinner/>
    )
}
  return (
    <div className="mt-4 flex-col space-y-2"> {/* space-y-8*/}
          <div className=' p-4'>
              <h1 className='font-semibold text-4xl'>Listening Test</h1>
              <h1 className='text-lg'>{catalogue.categoryName}</h1>
          </div>
          <div className=' px-4 flex-col space-y-4'>
              <div className='font-semibold  leading-8 text-red-500 bg-red-100 p-2'>
                  Listen the below recording carefully to fill in the blanks
              </div>
               {/* Audio element */}
               <div className='flex items-center justify-center'>
                    {countdown > 0 ? (
                        // Display countdown message
                        <div className="flex rounded-xl border-2">
                            <p className='text-gray-500 p-2'>Playing audio in </p>
                            <p className='p-2 bg-yellow-200 rounded-r-xl'>{formattedTime} sec</p>
                        </div>
                    ) : (
                        <AudioPlayer
                            audioSrc={currentQuestion?.questionAudioFile}
                            onAudioEnd={() => console.log('Audio ended')}
                            showAudioPlayer={showAudioPlayer}
                        />
                    )}
                </div>
              <div className='font-bold py-2 px-32 leading-10 text-xl'>Type the missing words in each blanks</div>
              <div className="font-semibold py-2 px-32 leading-10 ">{renderQuestionText(currentQuestion.questionText)}</div>
          </div>
          <div className="flex justify-end space-x-2 mt-4 px-8 py-2">
              <Link to="/pte/listening_tests/catalogue" className="bg-white border-2 py-2 px-4 rounded-md">Cancel </Link>
              <button 
                  className="bg-blue-700 text-white py-2 px-4 rounded-md"
                  onClick={handleAnalyzeAnswers}
              >
                  Analyse Answer
              </button>
          </div>
          <ScoreCard
            show={showScorecard}
            data={scoreData}
            onClose={() => handleClose()}
            onNext={()=>handleNextQuestion()}
            onRetry={()=>handleRetry()}
            questionText={currentQuestion.questionText}
          />
            <RetryModal
                isOpen={showRetry}
                onClose={() => handleRetryModal()}
                onBuyMembership={handleBuyMembership}
            />
    </div>
  )
}
