import React, { useEffect, useState } from 'react';
import star from '../../../assets/images/star.png';
import { ReactComponent as CloseGray } from '../../../assets/svg/close_grey.svg';
import { PiCaretRightBold } from 'react-icons/pi';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { ScoreCardAll as ScoreCardAllWriting } from '../WritingTests/ScoreCardAll';
import { ScoreCardAll as ScoreCardAllSpeaking } from './ScoreCardAll';

export const ScoreCardMain = ({ show, data, onClose ,canRetry,onRetry }) => {
    const [showScorecard, setShowScorecard] = useState(false);
    const [scoreData, setScoreData] = useState(null); // Store response data
    const [isLoading, setIsLoading] = useState(false);
    const [category, setCategory] = useState(null);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

    if (!show || !data) return null;
    //console.log(data) // data for full answer

    const { Result, overallResult } = data;

    const handleClick =(category)=>{
        setShowScorecard(true)
        setScoreData(Result[category].checkedAnswers)
        setCategory(category)
        setCurrentQuestionIndex(0);
    }

    const formatCategory =(category) =>{
        return category.replace(/([a-z])([A-Z])/g, '$1 $2')
    }

    const RenderScoreCard = (category) =>{
        switch (category) {
            case 'DescribeImage':
                return(
                    <ScoreCardAllWriting
                        show={showScorecard}
                        data={scoreData} 
                        onClose={() => setShowScorecard(false)}
                        currentQuestionIndex={currentQuestionIndex}
                        setCurrentQuestionIndex={setCurrentQuestionIndex}
                    />
                )
            default:
                return(
                    <ScoreCardAllSpeaking
                        show={showScorecard}
                        data={scoreData} 
                        onClose={() => setShowScorecard(false)}
                        currentQuestionIndex={currentQuestionIndex}
                        setCurrentQuestionIndex={setCurrentQuestionIndex}
                        category={category}
                    />
                )
        } 
    }

  return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white rounded-lg p-6 shadow-lg w-11/12 md:w-1/3 max-h-[90%] overflow-auto relative">
                <div className='flex justify-between '>
                    <h2 className="text-xl font-bold mb-2">Result</h2>
                    <CloseGray 
                        onClick={onClose} 
                        aria-label="Close"
                        className="w-4 h-4 cursor-pointer m-1" 
                    />
                </div>
                <h3 className="mb-2 text-gray-500">Analyse your answers with the correct answers</h3>
                <div className="flex justify-center items-center mb-4">
                    <div className="w-32 h-32 bg-yellow-100 rounded-full flex items-center justify-center space-x-2 border border-orange-200">
                        {/* Circular background */}
                        <div className="text-3xl font-bold">
                            {overallResult}
                        </div>
                        {/* Star Icon */}
                        <div>
                            <img 
                                src={star}
                                alt="star-image"
                                className='w-10 h-10'
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col space-y-4 p-4">
                    {Object.entries(Result).map(([category, { totalRating}]) => (
                        <div key={category} className="flex items-center justify-between bg-gray-100 p-4 rounded-lg shadow-md cursor-pointer"
                            onClick={() => handleClick(category)}
                        >
                            <span className="text-gray-700 font-semibold">{formatCategory(category)}</span>
                            <div className="flex space-x-2 ">
                                <div className="text-gray-500"> {totalRating}</div>
                                <div className="card-icon mt-1">
                                    <PiCaretRightBold />
                                </div>
                            </div>
                            
                        </div>
                    ))}
                </div>
                {canRetry && ( // Conditional rendering of the retry button
                    <div className='flex justify-end'>
                        <button 
                            onClick={onRetry } // Call onRetry when clicked
                            className="mt-4 bg-blue-500 text-white rounded-lg py-2 px-4 border-2"
                        >
                            Retry
                        </button>
                    </div>
                )}
            </div>
            {isLoading ? (
                    <div className="flex justify-center items-center h-64"> 
                        <LoadingSpinner /> 
                    </div>
                ) : (
                    RenderScoreCard(category)
            )}
        </div>
    );
}
