import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { createJwt } from '../../../../utils/helpers';
import toast from 'react-hot-toast';
import { analysisPteListeningSpecificQuestionAnswers } from '../../../../api/apiCall';
import RetryModal from '../../ReadingTests/components/RetryModal';
import {ScoreCard} from '../../WritingTests/components/ScoreCard';
import LoadingOverlay from '../../WritingTests/LoadingOverlay';
import { AudioPlayer } from '../../components/AudioPlayer';

const SummarizeSpokenItem = () => {
  const questions = useSelector
  ((state) => state.other.questions);
  const catalogue = useSelector((state) => state.other.catalogue);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [wordCount, setWordCount] = useState(0);
  const [answer, setAnswer] = useState('');
  const user = JSON.parse(localStorage.getItem("userData"));
  const navigate=useNavigate();
  const [loading, setLoading] = useState(false);
  const [showScorecard, setShowScorecard] = useState(false);
  const [scoreData, setScoreData] = useState(null); // Store response data
  const [showRetry, setShowRetry] = useState(false);

  const currentQuestion = questions[currentQuestionIndex];
  const audioRef = useRef(null);
  const [countdown, setCountdown] = useState(9); // Start countdown from 10 seconds
  const [formattedTime, setFormattedTime] = useState('00:09');
  const [showAudioPlayer, setShowAudioPlayer] = useState(false); // Initially hide the audio player
  const countdownStopped = useRef(false);

  const reStart = ()=>{
    setAnswer('');
    setWordCount(0);
    setFormattedTime('00:09')
    countdownStopped.current=false;
    setCountdown(9);
}

    useEffect(() => {
       reStart();
    }, [currentQuestionIndex,currentQuestion]);

    const resetAudio = () => {
        countdownStopped.current = true;
        setShowAudioPlayer(false);
      };    

    useEffect(() => {
        if (countdownStopped.current) return;
        if (countdown > 0) {
            // Update the countdown every second
            const countdownTimer = setInterval(() => {
                setCountdown(prev => {
                    const newCountdown = prev - 1;
                    
                    // Update formatted time immediately
                    const minutes = Math.floor(newCountdown / 60).toString().padStart(2, '0');
                    const seconds = (newCountdown % 60).toString().padStart(2, '0');
                    setFormattedTime(`${minutes}:${seconds}`);
                    
                    return newCountdown;
                });
            }, 1000);

            return () => clearInterval(countdownTimer); // Clean up the timer on unmount
        } else if(countdown===0) {
            // After countdown, show the audio player and play the audio
            setShowAudioPlayer(true);
        }
    }, [countdown]);

    const countWords = (text) => {
        return text.trim().split(/\s+/).filter(word => word.length > 0).length;
    };

      const handleTextChange = (e) => {
        const value = e.target.value;
        const words = countWords(value);
        setWordCount(words);
        setAnswer(value);
    }

    const formAnswers = async (queryId) => {
        const data={
            uid: user.uid,
            platform: user.platform,
            uniqueDeviceId: user.uniqueDeviceId,
            time: user.time,
            categoryId: catalogue.categoryId,
            queryId:queryId,
            questionNumbers:currentQuestion.uniqueQuestionNumber,
            answer:answer
        };
        const encryptedData = createJwt(data);
        const formData = new FormData();
        formData.append("encrptData", encryptedData);
        try{
            const response=await analysisPteListeningSpecificQuestionAnswers(formData)
            if (response.data.failure) {
                toast.error(response.data.errorMessage);
                return; // Exit if there's an error
            }
            else{
                return response.data.data;
            }
        }
        catch (error) {
            toast.error("Failed to fetch questions.");
            console.error("Error fetching questions:", error);
        }

    };

    const handleAnalyzeAnswers = async () => {

        if (wordCount<70) {
            // Show a notification if no answer is selected
            toast.error("Minimum Word Count is 70");
            return; 
        }

       resetAudio()
        let queryId = '';
        let response;
        setLoading(true)
        do {
            // Call the formAnswers function to get the response
            response = await formAnswers(queryId);
            
            if (response) {
                queryId = response.queryId; // Update queryId with the latest value
                const callbackTime = response.callBackTime* 1000; // Get the callback time
    
                await new Promise((resolve) => setTimeout(resolve, callbackTime)); // Wait for the callback time
            }
        } while (response && response.ans === '');
        setLoading(false)
        setScoreData(response.ans); // Set score data from response
        setShowScorecard(true); 
       // console.log(response.ans)
    };

    const handleNextQuestion=()=>{
        //setShowScorecard(false)
        if (currentQuestionIndex < questions.length - 1) {
            setShowScorecard(false)
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
        else{
            navigate(-1)
        }
    }

    const handleBuyMembership = () => {
        navigate("/buy-membership", { replace: true });
    };

    const handleRetry=()=>{
        //const isPaidUser = false;
        const isPaidUser = user.memberShip==="Active";
        if(!isPaidUser){
          setShowRetry(true)
        }
        else{
            reStart()
            setShowScorecard(false)
        }
        
    }
    const handleRetryModal=()=>{
        setShowRetry(false);
       navigate("/pte/listening_tests/catalogue")
    }

    const handleClose=()=>{
        setShowScorecard(false)
        navigate("/pte/listening_tests/catalogue")
    }

    return (
      <div className="mt-4 flex-col space-y-2"> {/* space-y-8*/}
          <div className=' p-4'>
              <h1 className='font-semibold text-4xl'>Listening Test</h1>
              <h1 className='text-lg'>{catalogue.categoryName}</h1>
          </div>
          <div className=' px-4 flex-col space-y-4'>
              <div className='font-semibold  leading-8 text-red-500 bg-red-100 p-2'>
                  You will hear a short report and need to write a summary for a student who missed it. Your summary should be between 50-70 words. It will be graded on how well you capture the main points and the clarity of your writing.
              </div>
              {/* Audio element */}
                <div className='flex items-center justify-center'>
                    {countdown > 0 ? (
                        // Display countdown message
                        <div className="flex rounded-xl border-2">
                            <p className='text-gray-500 p-2'>Playing audio in </p>
                            <p className='p-2 bg-yellow-200 rounded-r-xl'>{formattedTime} sec</p>
                        </div>
                    ) : (
                        <AudioPlayer
                            audioSrc={currentQuestion?.questionAudioFile}
                            onAudioEnd={() => console.log('Audio ended')}
                            showAudioPlayer={showAudioPlayer}
                        />
                    )}
                </div>
              <div className="font-bold py-2 px-32 leading-10 text-xl">Write your report within 50-70 words</div>
              <div className="relative px-32">
                  <textarea 
                      className="w-full p-2 bg-gray-100  rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      value={answer}
                      placeholder="Start writing your answer here..." 
                      rows="15"
                     onChange={handleTextChange} // You need this function to handle changes
                  />
  
                  {/* Character Counter */}
                  <div className="text-right text-gray-500 pt-2">
                      {wordCount}/70
                  </div>
              </div>
          </div>
  
          <div className="flex justify-end space-x-2 mt-4 px-8 py-2">
              <Link to="/pte/listening_tests/catalogue" className="bg-white border-2 py-2 px-4 rounded-md">Cancel </Link>
              <button 
                  className="bg-blue-700 text-white py-2 px-4 rounded-md"
                  onClick={handleAnalyzeAnswers}
              >
                  Analyse Answer
              </button>
  
          </div>
          {loading ? ( // Conditional rendering for loading state
            <div className="flex justify-center items-center h-64"> {/* Adjust height as needed */}
                <LoadingOverlay /> {/* Loading spinner */}
            </div>
        ) : (
            <ScoreCard
                show={showScorecard}
                data={scoreData}
                onClose={() => handleClose()}
                onNext={()=>handleNextQuestion()}
                onRetry={()=>handleRetry()}
                questionText={currentQuestion.questionText}
            />
        )}
        <RetryModal
                isOpen={showRetry}
                onClose={() => handleRetryModal()}
                onBuyMembership={handleBuyMembership}
            />
      </div>
    )
}

export default SummarizeSpokenItem