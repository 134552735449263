import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { createJwt } from '../../../../utils/helpers';
import { analysisPteSpeakingSpecificQuestionAnswers } from '../../../../api/apiCall';
import RetryModal from '../../ReadingTests/components/RetryModal';
import { ScoreCardSpeaking } from './ScoreCardSpeaking';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { Recording } from './Recording';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { AudioPlayer } from '../../components/AudioPlayer';

export const RepeatSentence = () => {
  const questions = useSelector((state) => state.other.questions);
  const catalogue = useSelector((state) => state.other.catalogue);
  const user = JSON.parse(localStorage.getItem("userData"));
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const currentQuestion = questions[currentQuestionIndex];
  const [initialCountdown, setInitialCountdown] = useState(5); // Countdown before recording 40
  const [recordingCountdown, setRecordingCountdown] = useState(catalogue.categoryName === "Answer Short Question" ? 6 : 10); // Countdown during recording
  const [formattedInitialTime, setFormattedInitialTime] = useState('00:05');// 40
  const [isRecording, setIsRecording] = useState(false); // State to start recording
  const [transcribedText, setTranscribedText] = useState(''); // Store the transcribed text
  const navigate=useNavigate();
  const [loading, setLoading] = useState(false);
  const [showScorecard, setShowScorecard] = useState(false);
  const [scoreData, setScoreData] = useState(null); // Store response data
  const [showRetry, setShowRetry] = useState(false);
  const initialTimerInterval = useRef(null);
  const recordingTimerInterval = useRef(null);
  const [showAudioPlayer, setShowAudioPlayer] = useState(false);

  // Track if countdown has been manually stopped
  const countdownStopped = useRef(false);

  const recordingStarted=useRef(false);

  const reStart = () =>{
    setFormattedInitialTime('00:05')
    setInitialCountdown(5);
    countdownStopped.current=false;
    setTranscribedText('')
  }

  useEffect(()=>{
    reStart();
  },[currentQuestion,currentQuestionIndex])

  useEffect(() => {
    // If the countdown is stopped manually (i.e., "Analyze Answer" clicked)
    if (countdownStopped.current) return;
    // Initial Countdown Timer
    if (initialCountdown > 0) {
      initialTimerInterval.current = setInterval(() => {
        setInitialCountdown((prev) => {
          const newCountdown = prev - 1;
          const minutes = Math.floor(newCountdown / 60).toString().padStart(2, '0');
          const seconds = (newCountdown % 60).toString().padStart(2, '0');
          setFormattedInitialTime(`${minutes}:${seconds}`);
          return newCountdown;
        });
      }, 1000);
      return () => clearInterval(initialTimerInterval.current);
    }
    setShowAudioPlayer(true)
  }, [initialCountdown]);

  useEffect(() => {
    if(isRecording){
        if (recordingCountdown > 0) {
        recordingTimerInterval.current = setInterval(() => {
            setRecordingCountdown((prev) => prev - 1);
        }, 1000);
        return () => clearInterval(recordingTimerInterval.current);
        }
        // When recordingCountdown reaches 0, handle analyze answers
        else if (recordingCountdown === 0) {
            handleAnalyzeAnswers();
        }
    }
  }, [isRecording, recordingCountdown]);

  useEffect(()=>{
    if(!showAudioPlayer && recordingStarted.current===true){
        setIsRecording(true)
    }
  },[showAudioPlayer])

  const resetRecorder=()=>{
    countdownStopped.current=true;
    recordingStarted.current=false;
    clearInterval(initialTimerInterval.current); // Clear initial countdown
    clearInterval(recordingTimerInterval.current); // Clear recording countdown
    setIsRecording(false);
  }

  const handleAnalyzeAnswers = async() => {
    if(showAudioPlayer){
        toast.error('Please listen to the audio patiently.')
        return;
    }
    resetRecorder();
    const data={
        uid: user.uid,
        platform: user.platform,
        uniqueDeviceId: user.uniqueDeviceId,
        time: user.time,
        questionNumbers:currentQuestion.uniqueQuestionNumber,
        categoryId: catalogue.categoryId,
        answer:transcribedText,
        audioDuration:(catalogue.categoryName === "Answer Short Question" ? 6 : 10),
    };
    const encryptedData = createJwt(data);
    const formData = new FormData();
    formData.append("encrptData", encryptedData);
    setLoading(true)
    try {
        const response = await analysisPteSpeakingSpecificQuestionAnswers(formData);
        if (!response.data.failure) {
              //console.log(response.data.data)
              setScoreData(response.data.data); // Set score data from response
              setShowScorecard(true); 

        } else {
            toast.error(response.data.errorMessage);
        }
    } catch (error) {
        toast.error("Failed to fetch questions.");
        console.error("Error fetching questions:", error);
    }finally {
        setLoading(false); // Stop loading
    }
  };

    const handleNextQuestion=()=>{
        //setShowScorecard(false)
        if (currentQuestionIndex < questions.length - 1) {
            setShowScorecard(false)
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
        else{
            handleClose();
        }
    }
    const handleBuyMembership = () => {
        navigate("/buy-membership", { replace: true });
    };

    const handleRetry=()=>{
        //const isPaidUser = false;
        const isPaidUser = user.memberShip==="Active";
        if(!isPaidUser){
         setShowRetry(true)
        }
        else{
            reStart();
            setShowScorecard(false)
        }
        
    }
    const handleRetryModal=()=>{
        setShowRetry(false);
        navigate("/pte/Speaking_tests/catalogue")
    }

    const handleClose=()=>{
        setShowScorecard(false)
        navigate("/pte/Speaking_tests/catalogue")
    }

    const handleAudioEnd = () => {
        //console.log('Audio has finished playing!');
        recordingStarted.current=true;
        setShowAudioPlayer(false)
        // Transition to recording phase
        if(catalogue.categoryName==="Answer Short Question"){
            setRecordingCountdown(6); 
        }
        else{
            setRecordingCountdown(10);
        }
    };

    const handleSkipTimer = ()=>{
        setInitialCountdown(0);
    }


  return (
    <div className="mt-4 flex-col space-y-2"> {/* space-y-8*/}
        <div className=' p-4'>
            <h1 className='font-semibold text-4xl'>Speaking Test</h1>
            <h1 className='text-lg'>{catalogue.categoryName}</h1>
        </div>
        <div className=' px-4 flex-col space-y-2'>
            <div className='font-semibold  leading-10 text-orange-500'>
                {catalogue.categoryName=== "Answer Short Question" ? (
                    "You'll hear a question. Please give a simple and short answer. Often just one or a few words are enough."
                    ):(
                    "You'll hear a sentence. Please repeat the sentence exactly as you heard it. You will hear the sentence only once."
                )}
            </div>
            <div className='flex items-center justify-center'>
                {!isRecording ? (
                    initialCountdown > 0 ? (
                        // Display countdown message
                        <div className="flex rounded-xl border-2">
                            <p className='text-gray-500 p-2'>Playing audio in </p>
                            <p className='p-2 bg-yellow-200 rounded-r-xl'>{formattedInitialTime} sec</p>
                        </div>
                    ) : (
                        <AudioPlayer
                            audioSrc={currentQuestion?.questionAudioFile}
                            onAudioEnd={handleAudioEnd}
                            showAudioPlayer={showAudioPlayer}
                        />
                    )
                ) :(
                    <Recording 
                        recordingCountdown={recordingCountdown} 
                        divisor={catalogue.categoryName === "Answer Short Question" ? 6 : 10}
                        onTranscribedText={setTranscribedText}
                    />
                )}
            </div>
        </div>
        <div className="flex justify-end space-x-2 mt-4 px-8 py-2">
            <button onClick={handleClose} className="bg-white border-2 py-2 px-4 rounded-md">Cancel </button>
            {initialCountdown > 0 ? (
                // Countdown Phase: Show "Skip Timer"
                <button 
                    onClick={handleSkipTimer} 
                    className="py-2 bg-blue-500 text-white rounded-md px-4"
                >
                    Skip Timer
                </button>
            ) : (
                <button 
                    className="bg-blue-700 text-white py-2 px-4 rounded-md"
                    onClick={handleAnalyzeAnswers}
                >
                    Analyse Answer
                </button>
            )}
        </div>
        {loading ? ( // Conditional rendering for loading state
              <div className="flex justify-center items-center h-64"> {/* Adjust height as needed */}
                  <LoadingSpinner/> {/* Loading spinner */}
              </div>
          ) : (
              <ScoreCardSpeaking
                  show={showScorecard}
                  data={scoreData}
                  onClose={() => handleClose()}
                  onNext={()=>handleNextQuestion()}
                  onRetry={()=>handleRetry()}
                  questionText={currentQuestion.questionText}
              />
          )}
          <RetryModal
                  isOpen={showRetry}
                  onClose={() => handleRetryModal()}
                  onBuyMembership={handleBuyMembership}
              />
    </div>
  )
}
