import React, { useEffect, useState } from 'react'
import "../components/Catalogue.css"
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCatalogue } from '../../../utils/redux/otherSlice';
import ProfileIconHeader from '../../../components/ProfileIconHeader';
import { PiCaretLeftBold, PiCaretRightBold } from 'react-icons/pi';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { Divider, Grid } from '@mantine/core';
import CatalogueCard from '../components/CatalogueCards/CatalogueCard';
import { createJwt } from '../../../utils/helpers';
import { pteSpeakingQuestionCount } from '../../../api/apiCall';
import { QuestionGroup } from './components/QuestionGroup';
export const SpeakingTestCatalogue = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedCatalogue, setSelectedCatalogue] = useState({ categoryId: null });
  const [totalQuestionCount, setTotalQuestionCount]=useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate()
  const dispatch= useDispatch();
  const user = JSON.parse(localStorage.getItem("userData"));
  const [ListeningCatalogue, setListeningCatalogue] = useState([]);

  useEffect(() => {
      const AcademicCore = user?.academicGenral;
      let catalogue = [
          {
              categoryName: "Read Aloud",
              categoryId: "0",
          },
          {
              categoryName: "Repeat Sentence",
              categoryId: "1",
          },
          {
              categoryName: "Describe Image",
              categoryId: "2",
          },
          {
              categoryName: "Answer Short Question",
              categoryId: "4",
          },
      ];
  
      if (AcademicCore === 2) {
        catalogue.splice(3, 0, {
          categoryName: "Retell Lecture", // For Academic examType
          categoryId: "3",
        });
     }
      else{
        catalogue.splice(3, 0, {
          categoryName: "Respond to situation Lecture", // For Core examType
          categoryId: "5",
        });
      }
  
      setListeningCatalogue(catalogue);
  }, [user?.academicGenral]);

  const handleCardClick= async (catalogue)=>{
      setSelectedCatalogue(catalogue);
      dispatch(setCatalogue(catalogue));
      const data={
          uid: user.uid,
          platform: user.platform,
          uniqueDeviceId: user.uniqueDeviceId,
          time: user.time,
          categoryId: catalogue.categoryId,
      };
      const encryptedData = createJwt(data);
      const formData = new FormData();
      formData.append("encrptData", encryptedData);
      setIsLoading(true);
      try {
          const response = await pteSpeakingQuestionCount(formData);
          if (response.data && !response.data.failure) {
              setTotalQuestionCount(response.data.data.totalQuestionCount);
              setSelectedCatalogue(catalogue);
              setModalOpen(true);
          } else {
              console.error(response.data.errorMessage);
          }
      } catch (err) {
          console.error("Error fetching question count:", err);
      } finally {
          setIsLoading(false);
      }
  }

  const closeModal = () => {
      setModalOpen(false);
  };
    
  return (
    <div className="w-full lg:max-h-screen overflow-scroll pb-5  ">
    <ProfileIconHeader />
    <main className="p-4 px-[51px] max-md:px-6">
        <div className="flex max-md:hidden items-center gap-2">
            <Link to="/home">Home</Link> <PiCaretRightBold />{" "}
            <Link to="/pte/Speaking">Speaking</Link> <PiCaretRightBold />
            <p className="text-primary-500">Tests</p>
        </div>
        <div className="pt-5 w-fit cursor-pointer flex gap-2 items-center" onClick={() => navigate('/pte/speaking')}>
            <button className="lg:hidden">
                <PiCaretLeftBold />
            </button>
            <span className="heading">
                Speaking Question Types
            </span>
        </div>
        <Divider my="xl" />
        {isLoading ? ( 
            <div className="flex justify-center items-center h-64">
                <LoadingSpinner />
            </div>
        ) : (
            <Grid>
                {ListeningCatalogue?.map((catalogue) => (
                    <Grid.Col key={catalogue.categoryId} span={{ base: 12, sm: 12, lg: 12, xl: 6 }}>
                        <CatalogueCard
                            catalogue={catalogue} 
                            onClick={() => handleCardClick(catalogue)}
                        />
                    </Grid.Col>
                ))}
            </Grid>
        )}
        <QuestionGroup
            isOpen={isModalOpen}
            onClose={closeModal}
            catalogue={selectedCatalogue}
            totalcount={totalQuestionCount}
        />
    </main>
</div>
  )
}

