import axios from "axios";

// export const baseUrl =
//   "https://fcc4-2401-4900-5992-cbcc-9562-e4bf-bef0-b7e.ngrok-free.app";
export const baseUrl = "https://ieltspro.babycode.org";

export const api = axios.create({
  baseURL: baseUrl,
  validateStatus: function (status) {
    return status >= 200 && status < 500; // Treat status code between 200 and 500 as successful
  },
});

export const Apis = {
  login: "/Login/",
  appInfo: "/AppInfo/",
  listeningPracticeData: "/getListeningPractiseData/",
  startListeningTest: "/startListeningTest/",
  listeningComments: "/fetchListeningTestComment/",
  vocabularyData: "/IeltsResource/",
  searchVocabulary: "/IeltsResourceSearch2/",
  likeListeningUserComment: "/likeCommentInListeningTest/",
  dislikeListeningUserComment: "/dislikeCommentInListeningTest/",
  listeningCommentRepliesList: "/fetchListeningTestCommentReplyies/",
  likeCommentReplyInListeningTest: "/likeCommentReplyInListeningTest/",
  dislikeCommentReplyInListeningTest: "/dislikeCommentReplyInListeningTest/",
  addCommentInListeningTest: "/addCommentInListeningTest/",
  addCommentReplyInListeningTest: "/addCommentReplyInListeningTest/",
  checkListeningTestBandScore: "/checkListeningTestBandScore/",
  getWritingCategorySubCategory: "/getWritingCategorySubCategory/",
  fetchWritingQuestionAnswer: "/fetchWritingQuestionAnswer/",
  submitWritingTestAnswer: "/CheckWritingV2/",
  readingData: "/getReadingPractiseData/",
  startReadingTest: "/startReadingTest/",
  checkReadingTestBandScore: "/checkReadingTestBandScore/",
  startReadingTestDownload: "/startReadingTestDownload/",
  submitSuggestion: "/SubmitSuggestion/",
  storiesList: "/StoriesList/",
  getSpeakingQuestionPaperList: "/GetSpeakingQuestionPaperList/",
  speakingQuestionListFetch: "/speakingQuestionListFetch/",
  practiceSpeakingSpecificQuestion: "/practiceSpeakingSpecificQuestion/",
  speakingTestV2: "/SpeakingTestV2/",
  calculateSpeakingBandByAvatarV2:'/CalculateSpeakingBandByAvatarV2/',
  getSpeakingQuestionPaperData:'/GetSpeakingQuestionPaperData/',
  getLanguagesList:'/GetLanguageList/',
  translateText:'/TranslateText/',
  analysisEnglishLanguageV3:'/AnalysisEnglishLanguageV3/',
  calculateSpeakingBandV2: '/CalculateSpeakingBandV2/',
  retakeThisTestV2:'/RetakeThisTestV2/',
  createPaymentQrCode:'/createPaymentQrCode/',
  userProfile:'/userProfile/',
  updateUserProfile:'/UpdateUserProfile/',
  applyPromoCode:'/applyPromoCode/',
  pteReadingQuestionCount:'/pteReadingQuestionCount/',
  pteReadingSpecificQuestionsFetch:'/pteReadingSpecificQuestionsFetch/',
  analysisPteReadingSpecificQuestionAnswers:'/analysisPteReadingSpecificQuestionAnswers/',
  pteReadingQuestionPaperFetch:'/pteReadingQuestionPaperFetch/',
  analysisPteReadingQuestionAnswers:'/analysisPteReadingQuestionAnswers/',
  pteReadingPreviousQuestionFetch:'/pteReadingPreviousQuestionFetch/',
  pteReadingQuestionPaperRetry:'/pteReadingQuestionPaperRetry/',
  pteWritingQuestionCount:'/pteWritingQuestionCount/',
  pteWritingSpecificQuestionsFetch:'/pteWritingSpecificQuestionsFetch/',
  analysisPteWritingSpecificQuestionAnswers:'/analysisPteWritingSpecificQuestionAnswers/',
  pteWritingQuestionPaperFetch:'/pteWritingQuestionPaperFetch/',
  analysisPteWritingQuestionAnswers:'/analysisPteWritingQuestionAnswers/',
  pteWritingPreviousQuestionFetch:'/pteWritingPreviousQuestionFetch/',
  pteWritingQuestionPaperRetry:'/pteWritingQuestionPaperRetry/',
  pteListeningQuestionCount:'/pteListeningQuestionCount/',
  pteListeningSpecificQuestionsFetch:'/pteListeningSpecificQuestionsFetch/',
  analysisPteListeningSpecificQuestionAnswers:'/analysisPteListeningSpecificQuestionAnswers/',
  pteListeningQuestionPaperFetch:'/pteListeningQuestionPaperFetch/',
  analysisPteListeningQuestionAnswers:'/analysisPteListeningQuestionAnswers/',
  pteListeningPreviousQuestionFetch:'/pteListeningPreviousQuestionFetch/',
  pteListeningQuestionPaperRetry:'/pteListeningQuestionPaperRetry/',
  pteSpeakingQuestionCount:'/pteSpeakingQuestionCount/',
  pteSpeakingSpecificQuestionsFetch:'/pteSpeakingSpecificQuestionsFetch/',
  analysisPteSpeakingSpecificQuestionAnswers:'/analysisPteSpeakingSpecificQuestionAnswers/',
  pteSpeakingQuestionPaperFetch:'/pteSpeakingQuestionPaperFetch/',
  analysisPteSpeakingQuestionAnswers:'/analysisPteSpeakingQuestionAnswers/',
  pteSpeakingPreviousQuestionFetch:'/pteSpeakingPreviousQuestionFetch/',
  pteSpeakingQuestionPaperRetry:'/pteSpeakingQuestionPaperRetry/'
};
