import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setSelectedMicrophone } from '../../../utils/redux/otherSlice';
import PopupWaveform from './PopupWaveform';

  export const MicrophoneSelector  = ({onClose, onMicrophoneSelected}) => {
    const [microphones, setMicrophones] = useState([]);
    const selectedMicrophone = useSelector((state) => state.other.selectedMicrophone);
    const [hasAccess, setHasAccess] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    const dispatch = useDispatch();
    const fetchMicrophones = async () => {
      try {// Check for microphone permission
          const permissionStatus = await navigator.permissions.query({ name: 'microphone' });
          if (permissionStatus.state === 'denied') {
              toast.error("Microphone access is denied. Please allow microphone access in your browser settings.");
              onClose();
              return; // Exit if microphone permission is denied
          }
      if (permissionStatus.state === 'prompt') {// If permission is not granted, prompt the user for permission
          await navigator.mediaDevices.getUserMedia({ audio: true })
              .catch(error => {
                  toast.error("Microphone access is required for the test. Please grant permission.");
                  onClose();
                  return;
              });
      }
      setHasAccess(true);
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioInputs = devices.filter(device => device.kind === 'audioinput');
      // Filter out low-quality microphones, keeping the default one
      const highQualityDevices = audioInputs.filter(device => {
          const label = device.label.toLowerCase();
          const isDefault = device.deviceId === 'default';
          const isHighQuality = !label.includes("communications"); // Exclude "communications" microphones
          return isDefault || isHighQuality;
      });
      setMicrophones(highQualityDevices);// Auto-select the default microphone if available, otherwise the first high-quality microphone
      const defaultMic = highQualityDevices.find(mic => mic.deviceId === 'default');
      if (defaultMic) {
          dispatch(setSelectedMicrophone(defaultMic.deviceId));
      } else if (highQualityDevices.length > 0) {
          dispatch(setSelectedMicrophone(highQualityDevices[0].deviceId));
      }
    } catch (error) {
        console.error('Error fetching microphones:', error);
    }
  };

  useEffect(() => {
    fetchMicrophones();
  }, []);

  if (!hasAccess) return null;

  const handleMicSelection = (e) => {
    dispatch(setSelectedMicrophone(e.target.value));
  };

  const handlePopupClose = ()=>{
    setShowPopup(false);
  }

  const handleContinue=()=>{
    setShowPopup(true);
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
        <div className="bg-white rounded-lg shadow-lg p-6 w-96">
            <h3 className="text-lg font-semibold">Select Microphone</h3>
            <div className='text-sm font-semibold text-blue-500'>Do not use bluetooth headphone use only wire mic or headphone or inbuild mic for better result and accuracy</div>
            <div className="my-4">
                <label htmlFor="micSelect" className="block text-gray-700">
                    Microphone:
                </label>
                <select
                    id="micSelect"
                    value={selectedMicrophone || "Default Microphone"}
                    onChange={handleMicSelection}
                    className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md"
                >
                    {microphones.map((mic) => (
                        <option key={mic.deviceId} value={mic.deviceId}>
                            {mic.label || `Microphone ${mic.deviceId}`}
                        </option>
                    ))}
                </select>
            </div>
            <div className="flex justify-end gap-4 mt-4">
                <button
                    className="border border-gray-400 font-medium py-2 px-6 rounded-md"
                    onClick={onClose}
                >
                    Cancel
                </button>
                <button className="bg-blue-500 text-white font-medium py-2 px-6 rounded-md" onClick={handleContinue}>
                    Continue
                </button>
            </div>
        </div>
        {showPopup && <PopupWaveform onClose={handlePopupClose} onSuccess={onMicrophoneSelected}/>}
    </div>
  )
}
