import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineAudio } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

export const Recording = ({recordingCountdown, divisor, onTranscribedText }) => {
    const selectedMicrophone = useSelector((state) => state.other.selectedMicrophone);
    const canvasRef = useRef(null);
    const audioStreamRef = useRef(null);
    const audioContextRef = useRef(null);
    const [showWaveform, setShowWaveform] = useState(false);
    const analyserRef = useRef(null);

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition,
    } = useSpeechRecognition();

    useEffect(() => {
        if (!browserSupportsSpeechRecognition) {
            console.error('Speech Recognition API is not supported in this browser.');
            return;
        }
    }, [browserSupportsSpeechRecognition]);

    useEffect(()=>{
        if (!listening) {
            setShowWaveform(true);
            SpeechRecognition.startListening({
                continuous: true,
                language: 'en-US',
                microphoneId: selectedMicrophone,
            });
        }
        return () => {// cleanup
            //console.log('cleanup')
            SpeechRecognition.stopListening();
            resetTranscript();
            setShowWaveform(false)
        }
    },[])

    useEffect(() => {
        onTranscribedText(transcript);
    }, [transcript]);

    useEffect(() => {
        let animationId;

        const startVisualization = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                audioStreamRef.current = stream;

                const audioContext = new AudioContext();
                const analyser = audioContext.createAnalyser();
                analyser.fftSize = 2048;

                const source = audioContext.createMediaStreamSource(stream);
                source.connect(analyser);

                audioContextRef.current = audioContext;
                analyserRef.current = analyser;

                const canvas = canvasRef.current;
                const ctx = canvas.getContext("2d");
                const bufferLength = analyser.fftSize;
                const dataArray = new Uint8Array(bufferLength);

                const drawWaveform = () => {
                    analyser.getByteTimeDomainData(dataArray);

                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    ctx.lineWidth = 2;
                    ctx.strokeStyle = "rgba(27, 202, 153, 1)";
                    ctx.beginPath();

                    const sliceWidth = canvas.width / bufferLength;
                    let x = 0;

                    dataArray.forEach((value, i) => {
                        const y = (value / 255) * canvas.height;
                        i === 0 ? ctx.moveTo(x, y) : ctx.lineTo(x, y);
                        x += sliceWidth;
                    });

                    ctx.lineTo(canvas.width, canvas.height / 2);
                    ctx.stroke();

                    animationId = requestAnimationFrame(drawWaveform);
                };

                drawWaveform();
            } catch (err) {
                console.error("Error accessing microphone:", err);
            }
        };

        if (showWaveform) {
            startVisualization();
        }

        return () => {
            if (audioStreamRef.current) {
                audioStreamRef.current.getTracks().forEach((track) => track.stop());
                audioStreamRef.current = null;
            }
            if (audioContextRef.current) {
                audioContextRef.current.close();
                audioContextRef.current = null;
            }
            cancelAnimationFrame(animationId);
        };
    }, [showWaveform]);

    return (
        <div className="flex flex-col justify-center items-center">
            <div className="relative flex justify-center items-center">
                <svg className="w-32 h-32">
                    <circle
                        cx="50%"
                        cy="50%"
                        r="45%"
                        stroke="rgba(27, 202, 153, 1)"
                        strokeWidth="10"
                        fill="none"
                    />
                    <circle
                        cx="50%"
                        cy="50%"
                        r="45%"
                        stroke="rgba(217, 217, 217, 1)"
                        strokeWidth="10"
                        fill="none"
                        strokeDasharray="320"
                        strokeDashoffset={(320 / divisor) * recordingCountdown}
                        className="transition-all duration-100 ease-in-out"
                    />
                </svg>
                <AiOutlineAudio className="absolute text-gray-300 text-5xl" />
            </div>
            {recordingCountdown > 0 ? (
                <>
                    <p className="text-lg">{recordingCountdown} sec</p>
                    <p className="text-md font-bold bg-gray-300 px-2 py-1 rounded-md">Answer Recording...</p>
                </>
            ) : (
                <p className="text-md font-bold bg-blue-200 text-blue-500 px-2 py-1 rounded-md mt-2">Answer Completed</p>
            )}
            {showWaveform && (
                <div
                    className=" w-12 h-12 p-2 bg-white shadow-lg rounded-full flex items-center justify-center"
                    style={{ zIndex: 1000 }}
                >
                    <canvas ref={canvasRef} width="50" height="50"></canvas>
                </div>
            )}
        </div>
    );
};
