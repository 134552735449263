import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { analysisPteReadingSpecificQuestionAnswers } from '../../../../api/apiCall';
import { createJwt } from '../../../../utils/helpers';
import ScoreCard from './ScoreCard';
import RetryModal from './RetryModal';
import { FillModal } from './FillModal';
import LoadingSpinner from '../../../../components/LoadingSpinner';

const FillInTheBlanks = () => {
    const questions = useSelector((state) => state.other.questions);
    const catalogue = useSelector((state) => state.other.catalogue);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [focusedBlank, setFocusedBlank] = useState(-1); // State to track which dropdown is focused
    const currentQuestion = questions[currentQuestionIndex];
    const [answers, setAnswers] = useState([]);
    const [answerOptions, setAnswersOptions] = useState([]);
    const user = JSON.parse(localStorage.getItem("userData"));
    const [showScorecard, setShowScorecard] = useState(false);
    const [scoreData, setScoreData] = useState(null); // Store response data
    const [showRetry, setShowRetry] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false); 
    const navigate=useNavigate();

    useEffect(()=>{
        const totalBlanks = (currentQuestion.questionText.match(/\*+/g) || []).length;

        setAnswers(Array(totalBlanks).fill(''));
        
        setAnswersOptions(currentQuestion.AnswerOptions);
    },[currentQuestion,currentQuestionIndex])

    const isOptionSelected = (option) => answers.includes(option);

    const handleAnswerSelection = (option) => {
        setAnswers((prevAnswers) => {
            const updatedAnswers = [...prevAnswers];
            updatedAnswers[focusedBlank] = option; // Set the answer for the focused blank
            return updatedAnswers;
        });
        setIsModalOpen(false); // Close modal after selection
    };

    const renderQuestionText = (questionText) => {
        //console.log(questionText)
        const parts = questionText.split(/(\*+)/); // Split text by asterisks
        let blankCounter = 0;

        return parts.map((part, index) => {
            if (/^\*+$/.test(part)) {
                const currentBlank = blankCounter;
                blankCounter++;

                return (
                    <button
                        key={`blank-${currentBlank}`}
                        onClick={() => {
                            setFocusedBlank(currentBlank); // Set focused blank
                           setIsModalOpen(true); // Open modal on click
                        }}
                        className={`px-2 py-1 my-2 border-2 rounded-md min-w-[140px] whitespace-nowrap ${answers[currentBlank] ? 'text-black border-gray-500' : 'text-gray-500 border-gray-400'}`}
                    >
                        {answers[currentBlank] || 'Select your answer'}
                    </button>
                );
            }
            return <span key={index}>{part}</span>; // Render text normally
        });
    };

    const handleAnalyzeAnswers = async () => {
        //console.log(answers)
        // const totalBlanks = Object.keys(currentQuestion.AnswerOptions).length;
        const allAnswersSelected = answers.every((answer) => answer !== '');
        
        if (!allAnswersSelected) {
            toast.error('Please select all answers.'); // Show error toast if not all answers are selected
            return;
        }

        const formattedAnswers = {};
        answers.forEach((answer, index) => {
            formattedAnswers[String(index + 1)] = answer; // Keys start from "1", "2", etc.
        });
        const data={
            uid: user.uid,
            platform: user.platform,
            uniqueDeviceId: user.uniqueDeviceId,
            time: user.time,
            questionNumbers:currentQuestion.uniqueQuestionNumber,
            categoryId: catalogue.categoryId,
            answer:formattedAnswers
        };
        const encryptedData = createJwt(data);
        const formData = new FormData();
        formData.append("encrptData", encryptedData);
        setIsLoading(true)
        try {
            const response = await analysisPteReadingSpecificQuestionAnswers(formData);
            if (!response.data.failure) {
                  //console.log(response.data.data)
                  setScoreData(response.data.data); // Set score data from response
                  setShowScorecard(true); 

            } else {
                toast.error(response.data.errorMessage);
            }
        } catch (error) {
            toast.error("Failed to fetch questions.");
            console.error("Error fetching questions:", error);
        }finally {
            setIsLoading(false); // Stop loading
        }

    };
    const handleNextQuestion=()=>{
        if (currentQuestionIndex < questions.length - 1) {
            setShowScorecard(false);
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
        else{
            navigate(-1)
        }
    }

    const handleBuyMembership = () => {
        navigate("/buy-membership", { replace: true });
    };

    const handleRetry=()=>{
        //const isPaidUser = false;
        const isPaidUser = user.memberShip==="Active";
        if(!isPaidUser){
          setShowRetry(true)
        }
        else{
            setAnswers([]);
            setShowScorecard(false)
        }
        
    }
    const handleRetryModal=()=>{
        setShowRetry(false);
       navigate("/pte/reading_tests/catalogue")
    }

    const handleClose = () =>{
        setShowScorecard(false);
        navigate("/pte/reading_tests/catalogue")
    }

    if(isLoading){
        return (
            <LoadingSpinner/>
        )
    }

    return (
        <div className="mt-4 flex-col space-y-2">
            <div className=' p-4'>
                <h1 className='font-semibold text-4xl'>Reading Test</h1>
                <h1 className='text-lg'>{catalogue.categoryName}</h1>
            </div>
            <div className=' px-4 flex-col space-y-2'>
                <div className='font-semibold  leading-10 text-orange-500'>
                    Below is a text with blank ,a list of choices will appear. Select the appropriate answer for each blanks.
                </div>
                <h3 className="font-semibold py-2 px-28 leading-10">{renderQuestionText(currentQuestion.questionText)}</h3>
            </div>
            <div className="flex justify-end space-x-2 mt-4 px-8 py-2">
                <Link to="/pte/reading_tests/catalogue" className="bg-white border-2 py-2 px-4 rounded-md">Cancel </Link>
                <button 
                    className="bg-blue-700 text-white py-2 px-4 rounded-md"
                    onClick={handleAnalyzeAnswers}
                >
                    Analyse Answer
                </button>

            </div>
            <FillModal
                isModalOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                answerOptions={answerOptions}
                handleAnswerSelection={handleAnswerSelection}
                isOptionSelected={isOptionSelected}
            />
            <ScoreCard
                show={showScorecard}
                data={scoreData}
                onClose={() => handleClose()}
                onNext={()=>handleNextQuestion()}
                onRetry={()=>handleRetry()}
                questionText={currentQuestion.questionText}
            />
            <RetryModal
                isOpen={showRetry}
                onClose={() => handleRetryModal()}
                onBuyMembership={handleBuyMembership}
            />
        </div>
    );
}
export default FillInTheBlanks;
