import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Recording } from './Recording';
import toast from 'react-hot-toast';
import RetryModal from '../../ReadingTests/components/RetryModal';
import { createJwt } from '../../../../utils/helpers';
import { analysisPteSpeakingSpecificQuestionAnswers } from '../../../../api/apiCall';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { ScoreCardSpeaking } from './ScoreCardSpeaking';

export const ReadAloud = () => {
  const questions = useSelector((state) => state.other.questions);
  const catalogue = useSelector((state) => state.other.catalogue);
  const user = JSON.parse(localStorage.getItem("userData"));
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const currentQuestion = questions[currentQuestionIndex];
  const [initialCountdown, setInitialCountdown] = useState(40); // Countdown before recording 40
  const [recordingCountdown, setRecordingCountdown] = useState(40); // Countdown during recording
  const [formattedInitialTime, setFormattedInitialTime] = useState('00:40');// 40
  const [isRecording, setIsRecording] = useState(false); // State to start recording
  const [transcribedText, setTranscribedText] = useState(''); // Store the transcribed text
  const navigate=useNavigate();
  const [loading, setLoading] = useState(false);
  const [showScorecard, setShowScorecard] = useState(false);
  const [scoreData, setScoreData] = useState(null); // Store response data
  const [showRetry, setShowRetry] = useState(false);
  const initialTimerInterval = useRef(null);
  const recordingTimerInterval = useRef(null);

  // Track if countdown has been manually stopped
  const countdownStopped = useRef(false);

  const reStart = () =>{
    setFormattedInitialTime('00:40')
    setInitialCountdown(40);
    countdownStopped.current=false;
    setTranscribedText('')
  }

  useEffect(()=>{
    reStart();
  },[currentQuestion,currentQuestionIndex])

  useEffect(() => {
    // If the countdown is stopped manually (i.e., "Analyze Answer" clicked)
    if (countdownStopped.current) return;
    // Initial Countdown Timer
    if (initialCountdown > 0 && !isRecording) {
      initialTimerInterval.current = setInterval(() => {
        setInitialCountdown((prev) => {
          const newCountdown = prev - 1;
          const minutes = Math.floor(newCountdown / 60).toString().padStart(2, '0');
          const seconds = (newCountdown % 60).toString().padStart(2, '0');
          setFormattedInitialTime(`${minutes}:${seconds}`);
          return newCountdown;
        });
      }, 1000);
      return () => clearInterval(initialTimerInterval.current);
    }
    // Recording Countdown Timer (starts after initial countdown ends)
    if (isRecording) {
      if(recordingCountdown > 0){
          recordingTimerInterval.current = setInterval(() => {
              setRecordingCountdown((prev) => prev - 1);
          }, 1000);
          return () => clearInterval(recordingTimerInterval.current);
      }
      else if(recordingCountdown===0){
          handleAnalyzeAnswers();
      }
    }
    else{
        setTranscribedText('');
    }
  }, [initialCountdown, recordingCountdown, isRecording]);

  useEffect(() => {
    if (initialCountdown === 0 && !isRecording && !countdownStopped.current){
      setIsRecording(true);
      setRecordingCountdown(40); // Reset the recording timer
    }
  }, [initialCountdown, isRecording]);

  const resetRecorder=()=>{
    countdownStopped.current = true; // Flag to stop countdown effect
    clearInterval(initialTimerInterval.current); // Clear initial countdown
    clearInterval(recordingTimerInterval.current); // Clear recording countdown
    setInitialCountdown(0);
    setRecordingCountdown(0);
    setIsRecording(false);
  }

  const handleAnalyzeAnswers = async() => {
    resetRecorder();
    console.log("Transcribed Text:", transcribedText);
    const data={
        uid: user.uid,
        platform: user.platform,
        uniqueDeviceId: user.uniqueDeviceId,
        time: user.time,
        questionNumbers:currentQuestion.uniqueQuestionNumber,
        categoryId: catalogue.categoryId,
        answer:transcribedText,
        audioDuration:40,
    };
    const encryptedData = createJwt(data);
    const formData = new FormData();
    formData.append("encrptData", encryptedData);
    setLoading(true)
    try {
        const response = await analysisPteSpeakingSpecificQuestionAnswers(formData);
        if (!response.data.failure) {
              //console.log(response.data.data)
              setScoreData(response.data.data); // Set score data from response
              setShowScorecard(true); 

        } else {
            toast.error(response.data.errorMessage);
        }
    } catch (error) {
        toast.error("Failed to fetch questions.");
        console.error("Error fetching questions:", error);
    }finally {
        setLoading(false); // Stop loading
    }
  };

    const handleNextQuestion=()=>{
        //setShowScorecard(false)
        if (currentQuestionIndex < questions.length - 1) {
            setShowScorecard(false)
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
        else{
            navigate(-1)
        }
    }
    const handleBuyMembership = () => {
        navigate("/buy-membership", { replace: true });
    };

    const handleRetry=()=>{
        //const isPaidUser = false;
        const isPaidUser = user.memberShip==="Active";
        if(!isPaidUser){
         setShowRetry(true)
        }
        else{
            reStart();
            setShowScorecard(false)
        }
        
    }
    const handleRetryModal=()=>{
        setShowRetry(false);
        navigate("/pte/Speaking_tests/catalogue")
    }

    const handleClose=()=>{
        setShowScorecard(false)
        navigate("/pte/Speaking_tests/catalogue")
    }

    const handleSkipTimer = ()=>{
      setInitialCountdown(0);
  }

  return (
    <div className="mt-4 flex-col space-y-2"> {/* space-y-8*/}
        <div className=' p-4'>
            <h1 className='font-semibold text-4xl'>Speaking Test</h1>
            <h1 className='text-lg'>{catalogue.categoryName}</h1>
        </div>
        <div className=' px-4 flex-col space-y-2'>
            <div className='font-semibold  leading-10 text-orange-500'>
                Look at the text below, In 35 seconds, you must read this text aloud as naturally and clearly as possible. You have 40 seconds to Read aloud.
            </div>
            <div className='flex items-center justify-center'>
                {!isRecording ? (
                    <div className="flex rounded-xl border-2">
                        <p className='text-gray-500 p-2'>Recording starts in </p>
                        <p className='p-2 bg-yellow-200 rounded-r-xl'>{formattedInitialTime} sec</p>
                    </div>
                ) :(
                    <Recording 
                        isRecording={isRecording}
                        recordingCountdown={recordingCountdown} 
                        divisor={40}
                        onTranscribedText={setTranscribedText}
                    />
                )}
            </div>
            <h3 className="font-semibold py-2 px-28 leading-10">{currentQuestion.questionText}</h3>
        </div>
        <div className="flex justify-end space-x-2 mt-4 px-8 py-2">
        <button onClick={handleClose} className="bg-white border-2 py-2 px-4 rounded-md">Cancel </button>
            {initialCountdown > 0 ? (
                // Countdown Phase: Show "Skip Timer"
                <button 
                    onClick={handleSkipTimer} 
                    className="py-2 bg-blue-500 text-white rounded-md px-4"
                >
                    Skip Timer
                </button>
            ) : (
                <button 
                    className="bg-blue-700 text-white py-2 px-4 rounded-md"
                    onClick={handleAnalyzeAnswers}
                >
                    Analyse Answer
                </button>
            )}
        </div>
        {loading ? ( // Conditional rendering for loading state
              <div className="flex justify-center items-center h-64"> {/* Adjust height as needed */}
                  <LoadingSpinner/> {/* Loading spinner */}
              </div>
          ) : (
              <ScoreCardSpeaking
                  show={showScorecard}
                  data={scoreData}
                  onClose={() => handleClose()}
                  onNext={()=>handleNextQuestion()}
                  onRetry={()=>handleRetry()}
                  questionText={currentQuestion.questionText}
              />
          )}
          <RetryModal
                  isOpen={showRetry}
                  onClose={() => handleRetryModal()}
                  onBuyMembership={handleBuyMembership}
              />
    </div>
  )
}
