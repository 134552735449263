import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { analysisPteWritingSpecificQuestionAnswers } from '../../../../api/apiCall';
import { createJwt } from '../../../../utils/helpers';
import RetryModal from '../../ReadingTests/components/RetryModal';
import LoadingOverlay from '../LoadingOverlay';
import {ScoreCard} from './ScoreCard';

export const Essay = () => {
    const questions = useSelector
    ((state) => state.other.questions);
    const catalogue = useSelector((state) => state.other.catalogue);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [wordCount, setWordCount] = useState(0);
    const [answer, setAnswer] = useState('');
    const user = JSON.parse(localStorage.getItem("userData"));
    const navigate=useNavigate();
    const [loading, setLoading] = useState(false);
    const [showScorecard, setShowScorecard] = useState(false);
    const [scoreData, setScoreData] = useState(null); // Store response data
    const [showRetry, setShowRetry] = useState(false);

    const currentQuestion = questions[currentQuestionIndex];

    useEffect(() => {
        setAnswer('');
        setWordCount(0)
    }, [currentQuestionIndex,currentQuestion,showScorecard]);


    const countWords = (text) => {
        return text.trim().split(/\s+/).filter(word => word.length > 0).length;
    };


    const handleTextChange = (e) => {
        const value = e.target.value;
        const words = countWords(value);
        setWordCount(words);
        setAnswer(value);
    }

    const formAnswers = async (queryId) => {
        const data={
            uid: user.uid,
            platform: user.platform,
            uniqueDeviceId: user.uniqueDeviceId,
            time: user.time,
            categoryId: catalogue.categoryId,
            queryId:queryId,
            questionNumbers:currentQuestion.uniqueQuestionNumber,
            answer:answer
        };
        const encryptedData = createJwt(data);
        const formData = new FormData();
        formData.append("encrptData", encryptedData);
        try{
            const response=await analysisPteWritingSpecificQuestionAnswers(formData)
            if (response.data.failure) {
                toast.error(response.data.errorMessage);
                return; // Exit if there's an error
            }
            else{
                return response.data.data;
            }
        }
        catch (error) {
            toast.error("Failed to fetch questions.");
            console.error("Error fetching questions:", error);
        }

    };

    const handleAnalyzeAnswers = async () => {

        if (wordCount<120) {
            // Show a notification if no answer is selected
            toast.error("Minimum Word Count is 120");
            return; 
        }
        let queryId = '';
        let response;
        setLoading(true)
        do {
            // Call the formAnswers function to get the response
            response = await formAnswers(queryId);
            
            if (response) {
                queryId = response.queryId; // Update queryId with the latest value
                const callbackTime = response.callBackTime* 1000; // Get the callback time
    
                await new Promise((resolve) => setTimeout(resolve, callbackTime)); // Wait for the callback time
            }
        } while (response && response.ans === '');
        setLoading(false)
        setScoreData(response.ans); // Set score data from response
        setShowScorecard(true); 
        console.log(response.ans)
    };


    const handleNextQuestion=()=>{
        //setShowScorecard(false)
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
        else{
            navigate(-1)
        }
    }

    const handleBuyMembership = () => {
        navigate("/buy-membership", { replace: true });
    };
    const handleRetry=()=>{
        //const isPaidUser = false;
        const isPaidUser = user.memberShip==="Active";
        if(!isPaidUser){
        setShowRetry(true)
        }
        else{
            setShowScorecard(false)
        }
        
    }
     const handleRetryModal=()=>{
         setShowRetry(false);
        navigate("/pte/reading_tests/catalogue")
     }

     const handleClose = () =>{
        setShowScorecard(false);
        navigate("/pte/writing_tests/catalogue")
    }

  return (
    <div className="mt-4 flex-col space-y-2"> {/* space-y-8*/}
        <div className=' p-4'>
            <h1 className='font-semibold text-4xl'>Writing Test</h1>
            <h1 className='text-lg'>{catalogue.categoryName}</h1>
        </div>
        <div className=' px-4 flex-col space-y-2 '>
            <div className='font-semibold  leading-10 text-orange-500 '>
                <span className='font-bold '>Read Paragraph below, </span>and answer by writing your answer.
            </div>
            <div className="font-semibold py-2 px-32 leading-10">{currentQuestion.questionText}</div>
            <div className="relative px-32">
                <textarea 
                    className="w-full p-2 bg-gray-100  rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={answer}
                    placeholder="Start writing your answer here..." 
                    rows="15"
                    onChange={handleTextChange} // You need this function to handle changes
                />

                {/* Character Counter */}
                <div className="text-right text-gray-500 pt-2">
                    {wordCount}/300
                </div>
            </div>
        </div>

        <div className="flex justify-end space-x-2 mt-4 px-8 py-2">
            <Link to="/pte/writing_tests/catalogue" className="bg-white border-2 py-2 px-4 rounded-md">Cancel </Link>
            <button 
                className="bg-blue-700 text-white py-2 px-4 rounded-md"
                onClick={handleAnalyzeAnswers}
            >
                Analyse Answer
            </button>

        </div>
        {loading ? ( // Conditional rendering for loading state
            <div className="flex justify-center items-center h-64"> {/* Adjust height as needed */}
                <LoadingOverlay /> {/* Loading spinner */}
            </div>
        ) : (
            <ScoreCard
                show={showScorecard}
                data={scoreData}
                onClose={() => handleClose()}
                onNext={()=>handleNextQuestion()}
                onRetry={()=>handleRetry()}
                questionText={currentQuestion.questionText}
            />
        )}
        <RetryModal
                isOpen={showRetry}
                onClose={() => handleRetryModal()}
                onBuyMembership={handleBuyMembership}
            />
    </div>
  )
}
