import { api, Apis } from "./constant";

// do login
export const login = (data) => {
  return api.post(Apis.login, data);
};

// get app info
export const getAppInformation = (data) => {
  return api.post(Apis.appInfo, data);
};

// get listening practice data
export const getListeningPractiseData = (data) => {
  return api.post(Apis.listeningPracticeData, data);
};

// start listening test
export const startListeningTest = (data) => {
  return api.post(Apis.startListeningTest, data);
};

// get listening test comments
export const getListeningComments = (data) => {
  return api.post(Apis.listeningComments, data);
};

// get vocabulary data
export const getVocabularyData = (data) => {
  return api.post(Apis.vocabularyData, data);
};

// get vocabulary category data
export const getVocabularyCategoryData = (data) => {
  return api.post(Apis.vocabularyData, data);
};

// search vocabulary
export const vocabularySearch = (data) => {
  return api.post(Apis.searchVocabulary, data);
};

// like user comment
export const likeListeningUserComment = (data) => {
  return api.post(Apis.likeListeningUserComment, data);
};

// dislike user comment
export const dislikeListeningUserComment = (data) => {
  return api.post(Apis.dislikeListeningUserComment, data);
};

// get listening comment replies
export const getListeningUserCommentReplies = (data) => {
  return api.post(Apis.listeningCommentRepliesList, data);
};

// like user comment reply in listening test
export const likeCommentReplyInListeningTest = (data) => {
  return api.post(Apis.likeCommentReplyInListeningTest, data);
};

// dislike user comment reply in listening test
export const dislikeCommentReplyInListeningTest = (data) => {
  return api.post(Apis.dislikeCommentReplyInListeningTest, data);
};

// add comment in listening test
export const addCommentInListeningTest = (data) => {
  return api.post(Apis.addCommentInListeningTest, data);
};

// add comment reply in listening test
export const addCommentReplyInListeningTest = (data) => {
  return api.post(Apis.addCommentReplyInListeningTest, data);
};

// check listening test band score
export const checkListeningTestBandScore = (data) => {
  return api.post(Apis.checkListeningTestBandScore, data);
};

// get writing category sub category
export const getWritingCategorySubCategory = (data) => {
  return api.post(Apis.getWritingCategorySubCategory, data);
};

// fetch writing questions
export const fetchWritingQuestionAnswer = (data) => {
  return api.post(Apis.fetchWritingQuestionAnswer, data);
};

// submit writing test answer
export const submitWritingTestAnswer = (data) => {
  return api.post(Apis.submitWritingTestAnswer, data);
};

// get reading category data
export const getReadingData = (data) => {
  return api.post(Apis.readingData, data);
};

// start reading test
export const startReadingTest = (data) => {
  return api.post(Apis.startReadingTest, data);
};

// check reading test band score
export const checkReadingTestBandScore = (data) => {
  return api.post(Apis.checkReadingTestBandScore, data);
};

// start reading test download
export const startReadingTestDownload = (data) => {
  return api.post(Apis.startReadingTestDownload, data);
};

// submit feedback
export const submitSuggestion = (data) => {
  return api.post(Apis.submitSuggestion, data);
};

// get stories
export const storiesList = (data) => {
  return api.post(Apis.storiesList, data);
};

// get speaking question paper
export const getSpeakingQuestionPaperList = (data) => {
  return api.post(Apis.getSpeakingQuestionPaperList, data);
};

// get speaking questions
export const getSpeakingQuestions = (data) => {
  return api.post(Apis.speakingQuestionListFetch, data);
};

// practice speaking question
export const practiceSpeakingSpecificQuestion = (data) => {
  return api.post(Apis.practiceSpeakingSpecificQuestion, data);
};

// get speaking test v2
export const getSpeakingTestV2 = (data) => {
  return api.post(Apis.speakingTestV2, data);
};

// calculate speaking band by AvatarV2
export const calculateSpeakingBandByAvatarV2 = (data) => {
  return api.post(Apis.calculateSpeakingBandByAvatarV2, data);
};

// get speaking question paper data
export const getSpeakingQuestionPaperData = (data) => {
  return api.post(Apis.getSpeakingQuestionPaperData, data);
};

// get languages list
export const getLanguagesList = (data) => {
  return api.post(Apis.getLanguagesList, data);
};

// translate text
export const translateText = (data) => {
  return api.post(Apis.translateText, data);
};

// analysis english language v3
export const analysisEnglishLanguageV3 = (data) => {
  return api.post(Apis.analysisEnglishLanguageV3, data);
};

// calculate speaking band v2
export const calculateSpeakingBandV2 = (data) => {
  return api.post(Apis.calculateSpeakingBandV2, data);
};

// retake speaking test
export const retakeThisTestV2 = (data) => {
  return api.post(Apis.retakeThisTestV2, data);
};

// create payment qr code
export const createPaymentQrCode = (data) => {
  return api.post(Apis.createPaymentQrCode, data);
};

// get user profile data
export const getUserProfile = (data) => {
  return api.post(Apis.userProfile, data);
};

// update user profile
export const updateUserProfile = (data) => {
  return api.post(Apis.updateUserProfile, data);
};

// apply promo code
export const applyPromoCode = (data)=>{
  return api.post(Apis.applyPromoCode, data);
}

// fetch  pte reading question count
export const pteReadingQuestionCount=(data)=>{
  return api.post(Apis.pteReadingQuestionCount,data);
}


export const pteReadingSpecificQuestionsFetch=(data)=>{
  return api.post(Apis.pteReadingSpecificQuestionsFetch,data);
}


 export const analysisPteReadingSpecificQuestionAnswers=(data)=>{
  return api.post(Apis.analysisPteReadingSpecificQuestionAnswers,data);
 }


 export const pteReadingQuestionPaperFetch=(data)=>{
   return api.post(Apis.pteReadingQuestionPaperFetch,data);
 }


 export const analysisPteReadingQuestionAnswers=(data)=>{
  return api.post(Apis.analysisPteReadingQuestionAnswers,data);
}


export const pteReadingPreviousQuestionFetch=(data)=>{
  return api.post(Apis.pteReadingPreviousQuestionFetch,data);
}


export const pteReadingQuestionPaperRetry=(data)=>{
  return api.post(Apis.pteReadingQuestionPaperRetry,data);
}


export const  pteWritingQuestionCount=(data)=>{
  return api.post(Apis. pteWritingQuestionCount,data);
}


export const pteWritingSpecificQuestionsFetch=(data)=>{
  return api.post(Apis.pteWritingSpecificQuestionsFetch,data);
}


export const analysisPteWritingSpecificQuestionAnswers=(data)=>{
  return api.post(Apis.analysisPteWritingSpecificQuestionAnswers,data);
 }


 export const pteWritingQuestionPaperFetch=(data)=>{
   return api.post(Apis.pteWritingQuestionPaperFetch,data);
 }


 export const analysisPteWritingQuestionAnswers=(data)=>{
  return api.post(Apis.analysisPteWritingQuestionAnswers,data);
}


export const pteWritingPreviousQuestionFetch=(data)=>{
  return api.post(Apis.pteWritingPreviousQuestionFetch,data);
}


export const pteWritingQuestionPaperRetry=(data)=>{
  return api.post(Apis.pteWritingQuestionPaperRetry,data);
}


export const  pteListeningQuestionCount=(data)=>{
  return api.post(Apis. pteListeningQuestionCount,data);
}

export const pteListeningSpecificQuestionsFetch=(data)=>{
  return api.post(Apis.pteListeningSpecificQuestionsFetch,data);
}

export const analysisPteListeningSpecificQuestionAnswers=(data)=>{
  return api.post(Apis.analysisPteListeningSpecificQuestionAnswers,data);
 }

 export const pteListeningQuestionPaperFetch=(data)=>{
  return api.post(Apis.pteListeningQuestionPaperFetch,data);
}


export const analysisPteListeningQuestionAnswers=(data)=>{
 return api.post(Apis.analysisPteListeningQuestionAnswers,data);
}


export const pteListeningPreviousQuestionFetch=(data)=>{
 return api.post(Apis.pteListeningPreviousQuestionFetch,data);
}


export const pteListeningQuestionPaperRetry=(data)=>{
 return api.post(Apis.pteListeningQuestionPaperRetry,data);
}

export const  pteSpeakingQuestionCount=(data)=>{
  return api.post(Apis.pteSpeakingQuestionCount,data);
}

export const pteSpeakingSpecificQuestionsFetch=(data)=>{
  return api.post(Apis.pteSpeakingSpecificQuestionsFetch,data);
}

export const analysisPteSpeakingSpecificQuestionAnswers=(data)=>{
  return api.post(Apis.analysisPteSpeakingSpecificQuestionAnswers,data);
 }

 export const pteSpeakingQuestionPaperFetch=(data)=>{
  return api.post(Apis.pteSpeakingQuestionPaperFetch,data);
}


export const analysisPteSpeakingQuestionAnswers=(data)=>{
 return api.post(Apis.analysisPteSpeakingQuestionAnswers,data);
}


export const pteSpeakingPreviousQuestionFetch=(data)=>{
 return api.post(Apis.pteSpeakingPreviousQuestionFetch,data);
}


export const pteSpeakingQuestionPaperRetry=(data)=>{
 return api.post(Apis.pteSpeakingQuestionPaperRetry,data);
}
