// UnsupportedBrowserDialog.js
import React from 'react';

const UnsupportedBrowser = ({ isOpen, closeDialog }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-xl font-semibold mb-4">Not Supported</h2>
        <p className="mb-4">Your browser does not support this feature. Please use one of the following supported browsers:</p>
        <ul className="list-disc pl-5 mb-4">
          <li>Google Chrome</li>
          <li>Microsoft Edge</li>
        </ul>
        <button
          onClick={closeDialog}
          className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default UnsupportedBrowser;
