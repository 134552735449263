import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { createJwt } from '../../../../utils/helpers';
import { analysisPteReadingSpecificQuestionAnswers } from '../../../../api/apiCall';
import RetryModal from './RetryModal';
import ScoreCardMultipleChoice from './ScoreCardMultipleChoice';
import LoadingSpinner from '../../../../components/LoadingSpinner';

const ReorderParagraph = () => {
    const questions = useSelector((state) => state.other.questions);
    const catalogue = useSelector((state) => state.other.catalogue);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const currentQuestion = questions[currentQuestionIndex];

    const user = JSON.parse(localStorage.getItem("userData"));

    const [showScorecard, setShowScorecard] = useState(false);
    const [scoreData, setScoreData] = useState(null); // Store response data
    const [showRetry, setShowRetry] = useState(false);

    const [leftSentences, setLeftSentences] = useState([]);
    const [rightSentences, setRightSentences] = useState([]);
    useEffect(() => {
        if (currentQuestion && currentQuestion.questionText) {
            // Set rightSentences based on the length of questionText
            setLeftSentences(currentQuestion.questionText)
            setRightSentences(Array(currentQuestion.questionText.length).fill(null));
        }
    }, [currentQuestion],[currentQuestionIndex]);

    const [selectedSentence, setSelectedSentence] = useState(null);

    const navigate= useNavigate();

    const handleAnalyzeAnswers = async () => {
        // console.log(currentQuestion.questionText.length)
        // console.log(rightSentences)

        if (rightSentences.includes(null)) {
            // Show a notification if there is a null value in rightSentences
            toast.error("Please ensure all selected answers are valid.");
            return; // Exit the function early
        }

        const data={
            uid: user.uid,
            platform: user.platform,
            uniqueDeviceId: user.uniqueDeviceId,
            time: user.time,
            questionNumbers:currentQuestion.uniqueQuestionNumber,
            categoryId: catalogue.categoryId,
            answer:rightSentences
        };
        const encryptedData = createJwt(data);
        const formData = new FormData();
        formData.append("encrptData", encryptedData);
        setIsLoading(true)
        //console.log(data)
        try {
            const response = await analysisPteReadingSpecificQuestionAnswers(formData);
            if (!response.data.failure) {
                  //console.log(response.data.data)
                  setScoreData(response.data.data); // Set score data from response
                  setShowScorecard(true); 

            } else {
                toast.error(response.data.errorMessage);
            }
        } catch (error) {
            toast.error("Failed to fetch questions.");
            console.error("Error fetching questions:", error);
        }finally {
            setIsLoading(false); // Stop loading
        }

    };

    const handleNextQuestion=()=>{
        setShowScorecard(false)
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
        else{
            navigate(-1)
        }
    }

    const handleBuyMembership = () => {
        navigate("/buy-membership", { replace: true });
    };

    const handleRetry=()=>{
        //const isPaidUser = false;
        const isPaidUser = user.memberShip==="Active";
        if(!isPaidUser){
          setShowRetry(true)
        }
        else{
            setShowScorecard(false)
            setLeftSentences(currentQuestion.questionText)
            setRightSentences(Array(currentQuestion.questionText.length).fill(null));
        }
        
    }
    const handleRetryModal=()=>{
        setShowRetry(false);
       navigate("/pte/reading_tests/catalogue")
    }
  
    const moveToRight = () => {
        if (selectedSentence !== null && leftSentences.includes(selectedSentence)) {
          // Find the index of the selected sentence in leftSentences
          const index = leftSentences.indexOf(selectedSentence);
          
          // Remove it from leftSentences and add it to the first empty slot in rightSentences
          if (index !== -1) {
            const newLeftSentences = [...leftSentences];
            newLeftSentences[index] = null;
            
            const newRightSentences = [...rightSentences];
            const emptyIndex = newRightSentences.indexOf(null);
            if (emptyIndex !== -1) {
              newRightSentences[emptyIndex] = selectedSentence;
            }
            
            setLeftSentences(newLeftSentences);
            setRightSentences(newRightSentences);
            setSelectedSentence(null); // Clear selection after moving
          }
        }
      };
      
      const moveToLeft = () => {
        if (selectedSentence !== null && rightSentences.includes(selectedSentence)) {
          // Find the index of the selected sentence in rightSentences
          const index = rightSentences.indexOf(selectedSentence);
          
          // Remove it from rightSentences and add it to the first empty slot in leftSentences
          if (index !== -1) {
            const newRightSentences = [...rightSentences];
            newRightSentences[index] = null;
            
            const newLeftSentences = [...leftSentences];
            const emptyIndex = newLeftSentences.indexOf(null);
            if (emptyIndex !== -1) {
              newLeftSentences[emptyIndex] = selectedSentence;
            }
            
            setRightSentences(newRightSentences);
            setLeftSentences(newLeftSentences);
            setSelectedSentence(null); // Clear selection after moving
          }
        }
      };

      const moveUp = () => {
        if (selectedSentence !== null && rightSentences.includes(selectedSentence)) {
            const index = rightSentences.indexOf(selectedSentence);
            if (index > 0) {
                const newRightSentences = [...rightSentences];
                // Swap the selected sentence with the one above it
                [newRightSentences[index], newRightSentences[index - 1]] = [newRightSentences[index - 1], newRightSentences[index]];
                setRightSentences(newRightSentences);
                setSelectedSentence(newRightSentences[index - 1]); // Update the selected sentence
            }
        }
    };
    
    const moveDown = () => {
        if (selectedSentence !== null && rightSentences.includes(selectedSentence)) {
            const index = rightSentences.indexOf(selectedSentence);
            if (index < rightSentences.length - 1) {
                const newRightSentences = [...rightSentences];
                // Swap the selected sentence with the one below it
                [newRightSentences[index], newRightSentences[index + 1]] = [newRightSentences[index + 1], newRightSentences[index]];
                setRightSentences(newRightSentences);
                setSelectedSentence(newRightSentences[index + 1]); // Update the selected sentence
            }
        }
    };

    const onDragStart = (sentence) => {
        setSelectedSentence(sentence);
    };

    const onDragOver = (event) => {
        event.preventDefault();
    };

    const onDrop = (targetArray) => {
        if (selectedSentence) {
            if (targetArray === 'right') {
                moveToRight(selectedSentence); // Move selected sentence to right
            } else if (targetArray === 'left') {
                moveToLeft(selectedSentence); // Move selected sentence to left
            }
        }
    }

    const handleClose = () =>{
        setShowScorecard(false);
        navigate("/pte/reading_tests/catalogue")
    }

    if(isLoading){
        return (
            <LoadingSpinner/>
        )
    }

  return (
    <div className="mt-4 flex-col space-y-2"> {/* space-y-8*/}
        <div className=' p-4'>
            <h1 className='font-semibold text-4xl'>Reading Test</h1>
            <h1 className='text-lg'>{catalogue.categoryName}</h1>
        </div>
        <div className=' px-4 flex-col space-y-2'>
            <div className='font-semibold  leading-10 text-orange-500'>
                Below is a paragraph. Please reorder it to arrange the content in the correct sequence
            </div>
            <div className="font-semibold py-2 px-4 xs:px-32">
                <div className="flex flex-col items-center sapce-y-4 xl:flex-row  xl:justify-center xl:space-x-4 p-4">
                    
                    {/* Left container */}
                    <div className="p-4 border-2 border-gray-300 rounded-md w-3/4 h-fit"
                        onDrop={() => onDrop('left')} // Handle the drop event
                        onDragOver={onDragOver}
                    >
                        <h3 className="text-lg font-semibold mb-4">Source</h3>
                        <div className="flex flex-col gap-2">
                            {Array(leftSentences.length).fill(null).map((_, index) => (
                                <div
                                    key={index}
                                    className={`flex justify-center py-2 px-4 items-center min-h-20 rounded-md w-full bg-gray-200 ${leftSentences[index] ? 'cursor-pointer' : ''}`}
                                    onClick={() => leftSentences[index] && setSelectedSentence(leftSentences[index])}
                                    draggable={!!leftSentences[index]}
                                    onDragStart={() => leftSentences[index] && onDragStart(leftSentences[index])}
                                >
                                    <span className={`whitespace-normal break-words text-left ${selectedSentence === leftSentences[index] ? 'text-blue-500' : ''}`}  style={{ width: '100%' }}>{leftSentences[index] || ''}</span>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Buttons */}
                    <div className="flex flex-col space-y-4  justify-center ">
                        <button
                            className="text-xl text-blue-500 border-2 border-blue-500 px-4 py-2 rounded-md"
                            onClick={moveToRight}
                            
                        >
                            →
                        </button>
                        <button
                            className="text-xl text-blue-500 border-2 border-blue-500 px-4 py-2 rounded-md"
                            onClick={moveToLeft}
                            
                        >
                            ←
                        </button>
                    </div>

                    {/* Right container */}
                    <div className="p-4 border-2 border-gray-300 rounded-md w-3/4 h-fit"
                         onDrop={() => onDrop('right')} // Handle the drop event
                         onDragOver={onDragOver} //
                    >
                        <h3 className="text-lg font-semibold mb-4">Target</h3>
                        <div className="flex flex-col gap-2">
                            {Array(rightSentences.length).fill(null).map((_, index) => (
                            <div
                                key={index}
                                className={`flex justify-center items-center py-2 px-4 min-h-20 rounded-md w-full bg-gray-200 ${rightSentences[index] ? 'cursor-pointer' : ''}`}
                                onClick={() => rightSentences[index] && setSelectedSentence(rightSentences[index])}
                                draggable={!!rightSentences[index]}
                                onDragStart={() => rightSentences[index] && onDragStart(rightSentences[index])}
                            >
                                <span className={`whitespace-normal break-words text-left ${selectedSentence === rightSentences[index] ? 'text-blue-500' : ''}`} style={{ width: '100%' }}>{rightSentences[index] || ''}</span>
                            </div>
                            ))}
                        </div>
                    </div>
                    {/* Up and Down Buttons */}
                    <div className="flex flex-col space-y-4 mt-4 justify-center">
                        <button
                            className="text-xl text-blue-500 border-2 border-blue-500 px-4 py-2 rounded-md"
                            onClick={moveUp}
                            disabled={ rightSentences.indexOf(selectedSentence) === 0}
                        >
                            ↑
                        </button>
                        <button
                            className="text-xl text-blue-500 border-2 border-blue-500 px-4 py-2 rounded-md"
                            onClick={moveDown}
                            disabled={ rightSentences.indexOf(selectedSentence) === rightSentences.length - 1}
                        >
                            ↓
                        </button>
                    </div>
                </div>
            </div>


            <div className="py-2 px-28">
                <div className='flex flex-col px-4 space-y-2'>
                    
                </div>
            </div>
        </div>
         <div className="flex justify-end space-x-2 mt-4 px-8 py-2">
            <Link to="/pte/reading_tests/catalogue" className="bg-white border-2 py-2 px-4 rounded-md">Cancel </Link>
            <button 
                className="bg-blue-700 text-white py-2 px-4 rounded-md"
                onClick={handleAnalyzeAnswers}
            >
                Analyse Answer
            </button>

        </div>
        <ScoreCardMultipleChoice
            show={showScorecard}
            data={scoreData}
            onClose={() => handleClose()}
            onNext={()=>handleNextQuestion()}
            onRetry={()=>handleRetry()}
            questionText={currentQuestion.questionText}
        />
        <RetryModal
            isOpen={showRetry}
            onClose={() => handleRetryModal()}
            onBuyMembership={handleBuyMembership}
        />
    </div>
  )
}

export default ReorderParagraph