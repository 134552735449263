import React, { useEffect, useState } from 'react'
import { PiCaretLeftBold, PiCaretRightBold } from 'react-icons/pi'
import ProfileIconHeader from '../../../components/ProfileIconHeader'
import { Link, useNavigate } from 'react-router-dom';
import test from "../../../assets/images/test.png"
import readinghome from "../../../assets/images/reading_main.png"
import UnsupportedBrowser from '../components/UnsupportedBrowser';
import { MicrophoneSelector } from '../components/MicrophoneSelector ';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { pteSpeakingPreviousQuestionFetch, pteSpeakingQuestionPaperRetry } from '../../../api/apiCall';
import { setRetry } from '../../../utils/redux/otherSlice';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { createJwt } from '../../../utils/helpers';
import { BuyMembershipModal } from '../ReadingTests/components/BuyMembershipModal';
import { TestDataNotAvailable } from '../ReadingTests/components/TestDataNotAvailable';
import { ScoreCardMain } from './ScoreCardMain';
import moment from 'moment';
import SpeechRecognition from 'react-speech-recognition';

export const SpeakingPte = () => {
    const navigate = useNavigate();
    const [isSupported, setIsSupported] = useState(true); // Flag to check if Web Speech API is supported
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [showMicModal, setShowMicModal] = useState(false);
    const user = JSON.parse(localStorage.getItem("userData"));
    const [testData, setTestData] = useState([]);
    const [showScorecard, setShowScorecard] = useState(false);
    const [scoreData, setScoreData] = useState(null);
    const [paper , setPaper]= useState(null);
    const [loading, setLoading] = useState(true);
    const dispatch=useDispatch();
    const [isTestDataNotAvailable, setIsTestDataNotAvailable] = useState(false);
    const [isBuyMembership, setIsBuyMembership] = useState(false);
    const [serverMessage, setServerMessage] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Ensure loading state is set initially
            const data = {
                uid: user.uid,
                platform: user.platform,
                uniqueDeviceId: user.uniqueDeviceId,
                time: user.time,
            };
            const encryptedData = createJwt(data);
            const formData = new FormData();
            formData.append("encrptData", encryptedData);
    
            try {
                const response = await pteSpeakingPreviousQuestionFetch(formData);
                if (!response.data.failure) {
                    const parsedResult = response.data.data.previousTest;
                    const updatedData = parsedResult.map((test, index) => {
                        const { created_at, getband, questionPaperId } = test;
    
                        // Format the created_at date and time
                        const formattedDate = moment(created_at).format("MMMM DD, YYYY");
                        const formattedTime = moment(created_at).format("hh:mm A");
    
                        // Return updated object for each test
                        return {
                            id: questionPaperId, // Unique ID from questionPaperId
                            testNo: `Test ${parsedResult.length - index}`,
                            date: formattedDate,
                            time: formattedTime,
                            band: getband || "--", // Default to '--' if getband is undefined
                        };
                    });
    
                    // Update state with formatted data
                    setTestData(updatedData);
                } else {
                    toast.error(response.data.errorMessage || "Failed to fetch questions.");
                }
            } catch (error) {
                toast.error("An error occurred while fetching questions.");
                console.error("Error fetching questions:", error);
            } finally {
                setLoading(false); // Ensure loading state is reset
            }
        };
    
        // Function to detect Safari reliably
        const isRealSafari = () => {
            const ua = navigator.userAgent;
            return (
                /Safari/.test(ua) &&
                /Version/.test(ua) &&
                !/Chrome/.test(ua) &&
                !/Edg/.test(ua) &&
                !/CriOS/.test(ua)
            );
        };
    
        // Check browser support for speech recognition and Safari
        if (!SpeechRecognition.browserSupportsSpeechRecognition() || isRealSafari()) {
            setIsSupported(false);
            setIsDialogOpen(true); // Open the dialog if not supported
        } else {
            fetchData();
        }
    }, []);
    

    const handleStartTest= () =>{
        setShowMicModal(true);
    }

    const handleMicSelected = () =>{
        navigate('/pte/Speaking_test_main');
    }

    const handleMicSelectionClose = ()=>{
        setShowMicModal(false);
    }

    const closeDialog = () => {
        setIsDialogOpen(false);
        navigate("/home")
    };

    const handleBuyMembership = () => {
        navigate("/buy-membership");
    };

    const handleRetry = async ()=>{
        const data={
            uid: user.uid,
            platform: user.platform,
            uniqueDeviceId: user.uniqueDeviceId,
            time: user.time
        };
        const encryptedData = createJwt(data);
        const formData = new FormData();
        formData.append("encrptData", encryptedData);
        try {
            const response = await pteSpeakingQuestionPaperRetry(formData);
            if (!response.data.failure) {
                const newQuestionId=response.data.data;
                console.log('newId: ',newQuestionId)
                const newPaperData = {
                    newQuestionId: newQuestionId,
                    paper: paper
                };
                // Store the object in localStorage
                localStorage.setItem("newpaper", JSON.stringify(newPaperData));
                dispatch(setRetry(true));
                navigate('/pte/Speaking_test_main')

            } else {
                setServerMessage(response.data.errorMessage);
                setIsBuyMembership(true)
            }
        } catch (error) {
            toast.error("Failed to fetch questions.");
            console.error("Error fetching questions:", error);
        }
    }

    const hadleClick=(item)=>{
        const storedData = localStorage.getItem(item.id);
        if (storedData) {
            const { results, questionPaper } = JSON.parse(storedData);
            setPaper(questionPaper)
            setScoreData(results)
            setShowScorecard(true)
        }
        else{
            setServerMessage('Test data not available on this device');
            setIsTestDataNotAvailable(true);
            return;
        }
    }

    if(isDialogOpen){
        return(
            <UnsupportedBrowser
            isOpen={isDialogOpen && !isSupported}
            closeDialog={closeDialog}
        />
        )
    }

    if(loading){
        return(
            <LoadingSpinner/>
        )
    }
  return (
    <div className="w-full lg:max-h-screen overflow-scroll pb-5 bg-background ">
        <ProfileIconHeader />
        <div className="bg-white sticky top-0 z-10">
            <div
                onClick={() => navigate("/home")}
                className="w-fit cursor-pointer flex gap-2 items-center p-3 lg:hidden"
            >
                <button>
                    <PiCaretLeftBold />
                </button>{" "}
                Speaking Practice
            </div>
        </div>
        <main className="p-4 px-12 ">
            <div className="flex items-center gap-2">
                <Link to="/home">Home</Link> <PiCaretRightBold />{" "}
                <p className="text-primary-500">Speaking</p>
            </div>
            <div className="flex flex-col space-y-8 items-center py-8 pr-4 ">
                <div className="flex flex-col  xl:flex-row w-full space-y-6 xl:space-y-0 xl:space-x-6 ">
                    <div className="w-full px-8 py-6 flex flex-col xs:flex-row space-x-4 bg-white  border-gray-100 border-2 rounded-2xl"> {/* 75% width div */}
                        <img
                           className="w-36 h-16 xs:w-56 xs:h-36 object-contain"
                           src={readinghome}
                           alt="reading home"
                           loading="lazy"
                        />
                        <div className="flex-col space-y-4">
                            <h2 className="font-bold text-2xl">7+ Band</h2>
                            <div className="font-semibold">
                                Practice 3-4 tests per day  and achieve<br></br>
                                a 7+ band score effortlessly!<br></br>
                            </div>
                            <button onClick={()=>handleStartTest()} className=" bg-blue-600 px-4 py-1 rounded-full text-white">
                                Start Speaking Test
                            </button>
                        </div>
                    </div>
                    <div className="px-8 py-6 w-full flex-col space-y-4 bg-blue-50 border-blue-100 border-2 rounded-2xl"> {/* 25% width div */}
                        <h2 className="font-bold text-2xl">Question Wise Practice</h2>
                        <div className="font-semibold">
                            Practice all category of questions to<br></br>
                            improve your score<br></br>
                        </div>
                        <button onClick={() => navigate("/pte/Speaking_tests/catalogue")} className=" bg-white px-4 py-1 rounded-full text-blue-500 border-blue-500 border-2">
                            Questions
                        </button>
                    </div>
                </div>
                <div className="flex w-full flex-col space-y-4">
                    <div className="w-full text-left"> 
                        <p className="my-2 font-semibold text-xl">All previous tests</p>
                    </div>
                    <div className="flex flex-col gap-2">
                        {testData.map((item) => (
                            <div 
                                key={item.id} 
                                className="bg-white p-4 rounded shadow flex justify-between items-center cursor-pointer"
                                onClick={() =>hadleClick(item)}
                            >
    
                                <div className="flex items-center gap-2">
                                    <img
                                        src={test}
                                        alt="test-icon"
                                        /> 
                                    <span className="font-semibold text-[12px] xs:text-lg">{item.testNo}</span>
                                </div>
                                
                                <div className="flex items-center space-x-2 text-gray-400 text-[12px] xs:text-lg">
                                    <p>{item.date}</p>
                                    <div className="w-1 h-1 rounded-full bg-gray-400"></div>
                                    <p>{item.time}</p>
                                </div>
                                
                                <div className=" flex flex-col items-center text-sm xs:text-lg">
                                    <p className="font-bold">{item.band}</p>
                                    <p className="text-gray-400">BAND</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </main>
        {showMicModal && (
            <MicrophoneSelector
                onClose={handleMicSelectionClose}
                onMicrophoneSelected={handleMicSelected}
            />
        )}
        <ScoreCardMain
            show={showScorecard}
            data={scoreData}
            onClose={() => setShowScorecard(false)}
            canRetry={true}
            onRetry ={handleRetry}
        />
        {isTestDataNotAvailable && (
            <TestDataNotAvailable
                message={serverMessage}
                onClose={() => setIsTestDataNotAvailable(false)}
            />
        )}
        {isBuyMembership && (
            <BuyMembershipModal
                message={serverMessage}
                onClose={() => setIsBuyMembership(false)}
                onBuyMembership={handleBuyMembership}
            />
        )}
    </div>
  )
}
