import React, { useState } from 'react';
import star from '../../../../assets/images/star.png';
import { ReactComponent as CloseGray } from '../../../../assets/svg/close_grey.svg';
import { useSelector } from 'react-redux';

export const ScoreCardSpeaking = ({ show, data, onClose ,onNext, onRetry, questionText}) => {

    const [showAnswers, setShowAnswers] = useState(false);
    const catalogue = useSelector((state) => state.other.catalogue);

    const handleShowAnswers = () => {
        if (!showAnswers) {
            setShowAnswers(true);
        }
    };
    
    if (!show) return null;

    // console.log(catalogue)
    // console.log(data)

    const { checkedAnswers, correctAnswers, overallResult, totalScore , answer} = data;
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
        <div className="bg-white rounded-lg p-6 shadow-lg w-11/12 md:w-1/3 max-h-[90%] overflow-auto relative">
            <div className='flex justify-between '>
                <h2 className="text-xl font-bold mb-2">Result</h2>
                <CloseGray 
                    onClick={onClose} 
                    aria-label="Close" 
                    className="w-4 h-4 cursor-pointer m-1" 
                />
            </div>
            <h3 className="mb-2 text-gray-500">Analyse your answers with the correct answers</h3>
            <div className="flex justify-center items-center mb-4">
                <div className="w-32 h-32 bg-yellow-100 rounded-full flex items-center justify-center space-x-2 border border-orange-200">
                    {/* Circular background */}
                    <div className="text-3xl font-bold">
                        {overallResult && typeof overallResult === 'object' 
                            ? overallResult.totalScore 
                            : overallResult 
                            ? overallResult 
                            : totalScore 
                            ? totalScore 
                            : null
                        }
                    </div>
                    {/* Star Icon */}
                    <div>
                        <img 
                            src={star}
                            alt="star-image"
                            className='w-10 h-10'
                        />
                    </div>
                </div>
            </div>
            <div className='flex-col space-y-4'>
                <p className="font-bold ">Your Answers</p>
                <div className="question-text flex flex-wrap">
                    {checkedAnswers ? checkedAnswers.map((answer, index) => {
                            const isCorrect = answer.Correct === '0'; 
                            return (
                                <span
                                    key={index}
                                    className={`text-lg ${isCorrect ? 'text-green-600' : 'text-red-600'} mr-2`}
                                    >
                                    {answer.Answer}
                                </span>
                            );
                        }) : (
                            answer? answer:null
                        )
                    }
                </div>
                {checkedAnswers && (
                    <div>
                        <button 
                            onClick={handleShowAnswers}
                            className="w-full py-2 border-2 border-gray-500  rounded-full hover:bg-blue-600 hover:text-white focus:outline-none"
                        >
                            Show all correct answers
                        </button>
                        <div className="py-4 mt-4">
                            {showAnswers && (
                                <div className="bg-gray-100 p-4 rounded-md">
                                    {correctAnswers}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
            <div className="flex justify-end space-x-2 mt-4">
                <button
                    className="border-2 text-black py-2 px-8 rounded-md"
                    onClick={onNext}
                >
                    Next
                </button>
                <button
                    className="bg-blue-600 text-white py-2 px-8 rounded-md"
                    onClick={onRetry}
                >
                    Retry
                </button>
                
            </div>
        </div>
    </div>
    );
}
